import { Flex, Box, Text, Button } from '@chakra-ui/react';

import { ChevronRightIcon, ChevronLeftIcon } from '../shared/Icon';

// eslint-disable-next-line react/prop-types
const Pagination = ({ currentPage, onChange, count }) => {
  const qtPages = Math.ceil(count / 10);
  const endPageRange = currentPage * 10 > count ? count : currentPage * 10;
  const initialPageRange =
    currentPage * 10 - 9 > endPageRange - 9
      ? currentPage * 10 - 9
      : endPageRange - 9;

  const getPageList = () => {
    if (qtPages <= 4) {
      const result = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= qtPages; i++) {
        result.push(i);
      }
      return result;
    }

    if (currentPage < 3) {
      return [1, 2, 3, '...', qtPages];
    }

    if (currentPage > qtPages - 3) {
      return [1, '...', qtPages - 3, qtPages - 2, qtPages - 1, qtPages];
    }

    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      qtPages,
    ];
  };

  const pageList = getPageList();

  return (
    <Flex
      justifyContent="flex-end"
      w="100%"
      h="65px"
      paddingX={8}
      alignItems="center"
    >
      <Box>
        <Text fontSize="sm" color="grey.200" mr={8}>
          {`Exibindo ${initialPageRange}-${endPageRange} de ${count} resultados`}
        </Text>
      </Box>
      <Flex>
        <Button
          bg="transparent"
          borderRadius="xl"
          isDisabled={currentPage === 1}
          onClick={() => onChange(currentPage - 1)}
        >
          <Box as={ChevronLeftIcon} stroke="brand-main" />
        </Button>
        {pageList.map(page => (
          <Button
            bg={page === currentPage ? 'grey.100' : 'transparent'}
            borderRadius="xl"
            onClick={() => onChange(page)}
            maxW={8}
            maxH={10}
            p={0}
            marginX={1}
            color="brand-main"
            isDisabled={page === '...'}
          >
            {page}
          </Button>
        ))}
        <Button
          bg="transparent"
          borderRadius="xl"
          isDisabled={currentPage === qtPages}
          onClick={() => onChange(currentPage + 1)}
        >
          <Box as={ChevronRightIcon} stroke="brand-main" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Pagination;
