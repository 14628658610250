/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgSearch(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={9.635}
        cy={8.635}
        r={4.635}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        stroke={props.color}
        strokeWidth={2}
        d="M12.596 11.309l3.984 3.984"
      />
    </svg>
  );
}

export default SvgSearch;
