import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  Checkbox,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postKpi, putKpi } from '../../services/api';

const schema = yup.object().shape({
  name: yup.string().required('campo obrigatório'),
  participant_role: yup.string().required('campo obrigatório'),
  is_entry_key: yup.bool().required('campo obrigatório'),
});

const KpiCreationModal = ({
  isOpen,
  stepId,
  onClose,
  onSuccess,
  kpiData,
  isEdit,
}) => {
  const { handleSubmit, errors, register, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState('');

  useEffect(() => {
    setValue('name', kpiData?.name);
    setValue('participant_role', kpiData?.participantRole?.name);
  }, [kpiData, setValue]);

  const onSubmit = async newKpi => {
    try {
      const postData = {
        id: kpiData?.id,
        id_step: stepId,
        ...newKpi,
      };

      const data = isEdit
        ? await putKpi({ postData })
        : await postKpi({ postData });

      onSuccess(data, isEdit);
      onClose();
    } catch (e) {
      // eslint-disable-next-line
      setError('O Nome do KPI já existe');
      console.log('erro', e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          borderBottomWidth="1px"
          borderBottomColor="grey.50"
          borderBottomStyle="solid"
        >
          Adicionar numa nova KPI
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingX={8}>
          <FormControl id="name" mb="32px" isInvalid={errors.name}>
            <FormLabel fontWeight="bold" color="grey.200">
              Nome da KPI
            </FormLabel>
            <Input
              ref={register}
              name="name"
              type="text"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <p style={{ fontSize: 12, color: 'red' }}>{error}</p>
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="participant_role"
            mb="32px"
            isInvalid={errors.participant_role}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Perfil Hierárquico
            </FormLabel>
            <Select
              placeholder="Selecione"
              name="participant_role"
              ref={register}
            >
              <option value="Cliente">Cliente</option>
              <option value="Trade">Trade</option>
              <option value="Gerente Regional">Gerente Regional</option>
              <option value="Gerente da Área">Gerente da Área</option>
              <option value="Executivo">Executivo</option>
              <option value="Distribuidor">Distribuidor</option>
            </Select>
            <FormErrorMessage>
              {errors.participant_role?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="is_entry_key" isInvalid={errors.is_entry_key}>
            <Checkbox
              colorScheme="blackAlpha"
              isInvalid={errors.is_entry_key}
              ref={register}
              name="is_entry_key"
              defaultChecked={kpiData?.is_entry_key}
            >
              Essa KPI é uma chave de entrada
            </Checkbox>
            <FormErrorMessage>{errors.is_entry_key?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack} p={8} spacing={8}>
          <Button
            width="100%"
            bg="transparent"
            mr={3}
            onClick={onClose}
            borderRadius="3xl"
            borderWidth="2px"
            borderColor="grey.500"
            borderStyle="solid"
            type="button"
          >
            Cancelar
          </Button>
          <Button
            width="100%"
            bg="brand-main"
            borderRadius="3xl"
            color="white"
            type="submit"
            _hover={{ bg: 'brand-brighter' }}
          >
            {isEdit ? 'Atualizar KPI' : 'Adicionar KPI'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

KpiCreationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  stepId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  kpiData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_entry_key: PropTypes.bool,
    participantRole: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isEdit: PropTypes.bool,
};

KpiCreationModal.defaultProps = {
  isEdit: false,
  kpiData: {
    name: '',
    is_entry_key: false,
    participantRole: {
      name: '',
    },
  },
};

export default KpiCreationModal;
