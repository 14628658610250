import PropTypes from 'prop-types';
import { Flex, Text, Link } from '@chakra-ui/react';
import { format } from 'date-fns';

const Header = ({ stepId, name, startDate, endDate }) => (
  <Flex mx={10} my={8} justify="space-between">
    <Flex>
      <Flex flexDir="column">
        <Text color="white" fontSize="sm">
          Nome da Etapa
        </Text>
        <Text color="white" fontSize="2xl">
          {name || ''}
        </Text>
      </Flex>
      <Flex flexDir="column" marginLeft={32}>
        <Text color="white" fontSize="sm">
          Data de Início
        </Text>
        <Text color="white" fontSize="2xl">
          {startDate ? `${format(new Date(startDate), 'dd/MM/yyyy')}` : ''}
        </Text>
      </Flex>
      <Flex flexDir="column" marginLeft={32}>
        <Text color="white" fontSize="sm">
          Data de Termino
        </Text>
        <Text color="white" fontSize="2xl">
          {endDate ? `${format(new Date(endDate), 'dd/MM/yyyy')}` : ''}
        </Text>
      </Flex>
    </Flex>

    <Flex flexDir="column" marginLeft={32} align="center">
      <Link href={`/dashboard/etapa/${stepId}/importacao`} style={{padding: "1rem 2rem", backgroundColor: "#333", color: "white", borderRadius: "5px"}}>
        Upload de Dados
      </Link>
    </Flex>
  </Flex>
);

Header.propTypes = {
  stepId: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

Header.defaultProps = {
  stepId: '',
  name: '',
  startDate: '',
  endDate: '',
};

export default Header;
