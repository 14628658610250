import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Flex,
  Text,
  Image,
  Box,
  Button,
  HStack,
  FormLabel,
} from '@chakra-ui/react';

import InputFile from '../shared/InputFile';

const ImportCard = ({
  onChange,
  isLoading,
  fileName,
  onSubmit,
  handleSheetModel,
  inputFileRef,
  dataValues,
  setDataValues,
  handleDownloadExample,
}) => (
  <Box border="2px solid #F0F0F9" maxW="630px" padding={8} borderRadius="md">
    <Flex alignItems="center" mb={8}>
      <Image
        width={24}
        height={24}
        objectFit="cover"
        src="/images/people-sheet.svg"
        alt=""
      />
      <Flex marginLeft={8} flexDir="column">
        <Text as="h3" fontSize="md" color="black" fontWeight="bold" mb={3}>
          Planilha de Métricas
        </Text>
        <FormLabel>Data de Referência dos Dados</FormLabel>
        <input type="date" onChange={e => setDataValues(e.target.value)} />
      </Flex>
    </Flex>
    <InputFile
      onChange={onChange}
      loading={isLoading}
      inputFileRef={inputFileRef}
      fileName={fileName}
    />
    <a
      href="/modelo.xlsx"
      style={{
        textAlign: 'center',
        textDecoration: 'underline',
        width: '100%',
        display: 'block',
      }}
      // w="100%"
      // bg="black"
      // color="white"
      // borderRadius="3xl"
      // _hover={{ bg: 'brand-brighter' }}
      // loading={isLoading}
    >
      Download de Modelo
    </a>
    <HStack mt={8} spacing={8}>
      <Button
        w="100%"
        bg="black"
        color="white"
        borderRadius="3xl"
        _hover={{ bg: 'brand-brighter' }}
        loading={isLoading}
        onClick={onSubmit}
      >
        Enviar planilha
      </Button>
    </HStack>
  </Box>
);

ImportCard.propTypes = {
  ...InputFile.propTypes,
  onSubmit: PropTypes.func.isRequired,
  handleSheetModel: PropTypes.func.isRequired,
};

ImportCard.defaultProps = {
  ...InputFile.defaultProps,
};

export default ImportCard;
