import { Center, Spinner } from '@chakra-ui/react';

const LoadingState = () => (
  <Center
    as="main"
    border="1px solid #F0F0F9"
    borderRadius="lg"
    margin={4}
    backgroundColor="white"
    maxWidth="calc(100% - 30px)"
    width="100%"
    flexDir="column"
    display="flex"
    alignItems="center"
  >
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="brand-highlight"
      color="brand-main"
      size="xl"
    />
  </Center>
);

export default LoadingState;
