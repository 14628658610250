/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Flex, Center, useRadio, useRadioGroup } from '@chakra-ui/react';

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Center
      as="label"
      w="100%"
      h="100%"
      overflow="hidden"
      _first={{
        borderRight: '1px solid #F0F0F9',
        borderLeftRadius: 'lg',
      }}
      _last={{
        borderRightRadius: 'lg',
      }}
    >
      <input {...input} />
      <Center
        {...checkbox}
        fontSize="xs"
        w="100%"
        h="100%"
        bg="#F9F9FF"
        cursor="pointer"
        color="grey.200"
        _checked={{
          fontWeight: 'bold',
          bg: 'white',
          color: '#4C6EF5',
        }}
      >
        {props.children}
      </Center>
    </Center>
  );
}

function ConditionRadioButton({ optionValue = 'E', onChange, ...rest }) {
  const options = ['E', 'OU'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'condition',
    defaultValue: optionValue,
    onChange: e => onChange(e),
  });

  const group = getRootProps();

  return (
    <Flex
      {...group}
      flexDir="row"
      my={3}
      maxW={20}
      minH={7}
      justifyContent="space-between"
      border="1px solid #F0F0F9"
      borderRadius="lg"
      position="relative"
      _before={{
        content: '""',
        height: 3,
        width: '1px',
        background: 'grey.700',
        position: 'absolute',
        top: '-13px',
        left: 'calc(50% - 1px)',
      }}
      _after={{
        content: '""',
        height: 3,
        width: '1px',
        background: 'grey.700',
        position: 'absolute',
        bottom: '-13px',
        left: 'calc(50% - 1px)',
      }}
      {...rest}
    >
      {options.map(value => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </Flex>
  );
}

export default ConditionRadioButton;
