import { useState, Fragment } from 'react';
import { Select, Flex, Text, useDisclosure, Input } from '@chakra-ui/react';

import ConditionalRadioButton from './ConditionalRadioButton';
import InputAutoComplete from './InputAutoComplete';
import VariableBadge from './VariableBadge';
import ModalRankingPrizes from './ModalRankingPrizes';
import { useKpi } from '../../hooks/useKpi';
import EmptyVariableList from './EmptyVaribleList';

const Main = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [positionEdit, setPositionEdit] = useState(null);
  const [editRankingList, setEditRankingList] = useState([
    {
      prizeType: '',
      prize: [],
      prizeCurrentValue: '',
      positionType: '',
      position: [],
      positionCurrentValue: '',
    },
  ]);
  const [editTiebreakerList, setEditTiebreakerList] = useState([
    {
      tiebreaker: [],
      currentValue: '',
    },
  ]);

  const {
    variableList,
    equationList,
    onConditionChange,
    autoCompleteValues,
    onEquationChange,
    onEquationDescriptionChange,
    onEquationConfirm,
    onEquationKeyDown,
    handleAddEquation,
    handleRemoveEquation,
    prizeList,
    onPrizeTypeSelect,
    onPrizeChange,
    onPrizeConfirm,
    onPrizeKeyDown,
    handleAddPrize,
    onUpdatePrize,
  } = useKpi();

  const handleEditRankingPrize = (rankingList, tiebreakerList, position) => {
    console.log(rankingList, tiebreakerList, position);
    setPositionEdit(position);
    setEditTiebreakerList(
      tiebreakerList.map(item => ({
        currentValue: '',
        tiebreaker: item ? [item] : [],
      })),
    );

    console.log('Ranking List: ', rankingList);
    setEditRankingList(rankingList);
    onOpen();
  };

  const onSuccess = (tiebreakerList, rankingList) => {
    onUpdatePrize(tiebreakerList, rankingList, positionEdit);
    setPositionEdit(null);
    onClose();
  };

  if (!variableList?.length) {
    return <EmptyVariableList />;
  }

  return (
    <>
      <Flex w="100%" flexDir="column">
        <Flex
          w="100%"
          h="60px"
          px="30px"
          alignItems="center"
          borderBottom="1px solid #F0F0F9"
        >
          <Text fontSize="xl" color="grey.300">
            Cálculos e Premiações
          </Text>
        </Flex>
        <Flex px={8} py={4} flexDir="column">
          <Text color="grey.300" fontSize="md" fontWeight="bold" ml={7} mb={3}>
            Se
          </Text>
          <Flex
            border="1px solid #DDDDEE"
            h="100%"
            w="100%"
            p={4}
            flexDir="column"
            position="relative"
            borderRadius="md"
            mb={5}
            _after={{
              content: '""',
              height: '20px',
              width: '1px',
              background: '#DDDDEE',
              position: 'absolute',
              top: '-20px',
              left: '18px',
            }}
            _before={{
              content: '""',
              height: '7px',
              width: '7px',
              borderRadius: '50%',
              border: '2px solid #4C6EF5',
              position: 'absolute',
              top: '-27px',
              left: '15px',
            }}
          >
            {equationList.map(
              ({ id, equation, currentValue, prefix, description }, index) => (
                <Fragment key={index}>
                  {prefix && (
                    <ConditionalRadioButton
                      onChange={value => onConditionChange(value, index)}
                      optionValue={prefix}
                      ml={4}
                    />
                  )}
                  <Flex
                    border="1px solid #F0F0F9"
                    borderLeft="3px solid #4C6EF5"
                    key={id}
                    h={12}
                    pl={4}
                    w="100%"
                    minH={12}
                    maxW="100%"
                    height="100%"
                    alignItems="center"
                    borderRadius="md"
                    flexWrap="wrap"
                  >
                    {equation.map((equationItem, i) => (
                      <VariableBadge
                        key={i}
                        variable={equationItem}
                        color="#4C6EF5"
                        bg="#F0F3FF"
                      />
                    ))}
                    <InputAutoComplete
                      inputItems={autoCompleteValues}
                      onChange={newValue => onEquationChange(newValue, index)}
                      value={currentValue}
                      onConfirm={() => onEquationConfirm(currentValue, index)}
                      onKeydown={event =>
                        onEquationKeyDown(event.code, currentValue, index)
                      }
                    />
                    <Input
                      style={{ maxWidth: 300, margin: '0 10px' }}
                      placeholder="Adicione a Descrição"
                      onChange={e =>
                        onEquationDescriptionChange(e.target.value, index)
                      }
                      value={description}
                    />
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                      onClick={() => handleRemoveEquation(id)}
                      style={{ borderRight: '5px' }}
                    >
                      <img src="/icons/delete.svg" alt="Deletar" />
                    </button>
                  </Flex>
                </Fragment>
              ),
            )}
            <Flex
              border="1px dashed #DDDDEE"
              minH={12}
              pl={4}
              w="100%"
              alignItems="center"
              borderRadius="md"
              onClick={handleAddEquation}
              cursor="pointer"
              mt={4}
            >
              <Text fontSize="sm" color="grey.200">
                Adicionar nova regra...
              </Text>
            </Flex>
          </Flex>
          <Text color="grey.300" fontSize="md" fontWeight="bold" ml={7} mb={3}>
            A premiação será de
          </Text>
          <Flex
            border="1px solid #DDDDEE"
            height="100%"
            w="100%"
            p={4}
            flexDir="column"
            position="relative"
            borderRadius="lg"
            _after={{
              content: '""',
              height: '20px',
              width: '1px',
              background: '#DDDDEE',
              position: 'absolute',
              top: '-20px',
              left: '18px',
            }}
            _before={{
              content: '""',
              height: '7px',
              width: '7px',
              borderRadius: '50%',
              border: '2px solid #2AC644',
              position: 'absolute',
              top: '-27px',
              left: '15px',
            }}
          >
            {prizeList.map(
              (
                {
                  type,
                  currentValue,
                  equation,
                  ranking_list: rankingList,
                  tiebreaker,
                },
                index,
              ) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <Flex
                      key={index}
                      h={7}
                      maxW={10}
                      my={3}
                      ml={4}
                      position="relative"
                      _before={{
                        content: '""',
                        height: 3,
                        width: '1px',
                        background: 'grey.700',
                        position: 'absolute',
                        top: '-13px',
                        left: 'calc(50% - 1px)',
                      }}
                      _after={{
                        content: '""',
                        height: 3,
                        width: '1px',
                        background: 'grey.700',
                        position: 'absolute',
                        bottom: '-13px',
                        left: 'calc(50% - 1px)',
                      }}
                      alignItems="center"
                      justifyContent="center"
                      border="1px solid #F0F0F9"
                      borderRadius="lg"
                    >
                      <Text fontSize="xs" color="#2AC644" fontWeight="bold">
                        E
                      </Text>
                    </Flex>
                  )}
                  <Flex
                    border="1px solid #F0F0F9"
                    borderLeft="3px solid #2AC644"
                    h={12}
                    w="100%"
                    alignItems="center"
                    borderRadius="md"
                  >
                    <Select
                      border="none"
                      w="100%"
                      size="lg"
                      maxW="120px"
                      fontSize="sm"
                      color="grey.200"
                      _focus={{
                        outline: 'none',
                      }}
                      value={type}
                      onChange={event =>
                        onPrizeTypeSelect(event.target.value, index)
                      }
                    >
                      <option value="ranking">Ranking</option>
                      <option value="calc">Cálculo</option>
                    </Select>
                    <Flex
                      h="100%"
                      borderLeft="1px solid #DDDDEE"
                      pl={4}
                      maxW="100%"
                      height="100%"
                      alignItems="center"
                    >
                      {type === 'calc' ? (
                        <>
                          {equation.map((equationItem, i) => (
                            <VariableBadge
                              key={i}
                              variable={equationItem}
                              color="#2AC644"
                              bg="#EBFFEE"
                            />
                          ))}
                          <InputAutoComplete
                            inputItems={autoCompleteValues}
                            onChange={newValue =>
                              onPrizeChange(newValue, index)
                            }
                            value={currentValue}
                            onConfirm={() =>
                              onPrizeConfirm(currentValue, index)
                            }
                            onKeydown={event =>
                              onPrizeKeyDown(event.code, currentValue, index)
                            }
                          />
                        </>
                      ) : (
                        <Text
                          fontSize="sm"
                          color="#2AC644"
                          fontWeight="bold"
                          cursor="pointer"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          onClick={() =>
                            handleEditRankingPrize(
                              rankingList,
                              tiebreaker,
                              index,
                            )
                          }
                        >
                          Editar Regras de Ranqueamento Personalizadas
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Fragment>
              ),
            )}
            <Flex
              border="1px dashed #DDDDEE"
              h={12}
              pl={4}
              w="100%"
              alignItems="center"
              borderRadius="md"
              cursor="pointer"
              onClick={handleAddPrize}
              mt={4}
            >
              <Text fontSize="sm" color="grey.200">
                Adicionar nova premiação...
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <ModalRankingPrizes
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={onSuccess}
        onCancel={onClose}
        initialRankingList={editRankingList}
        initialTiebreakerList={editTiebreakerList}
        autoCompleteValues={autoCompleteValues}
      />
    </>
  );
};

export default Main;
