/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgBargraph(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        d="M10 15V8M14 15V5M6 15v-4"
      />
    </svg>
  );
}

export default SvgBargraph;
