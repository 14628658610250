import PropTypes from 'prop-types';

import { Flex, Text, Box } from '@chakra-ui/react';
import { CheckCircledIcon } from '../shared/Icon';

const Header = ({ kpiName, profile, isEntryKey }) => (
  <Flex mx={10} my={8} alignItems="flex-end">
    <Flex flexDir="column">
      <Text color="white" fontSize="sm">
        Nome da KPI
      </Text>
      <Text color="white" fontSize="2xl">
        {kpiName}
      </Text>
    </Flex>
    <Flex flexDir="column" marginLeft={32}>
      <Text color="white" fontSize="sm">
        Perfil Hierárquico
      </Text>
      <Text color="white" fontSize="2xl">
        {profile}
      </Text>
    </Flex>
    {isEntryKey && (
      <Flex marginLeft={32} alignItems="center">
        <Box as={CheckCircledIcon} stroke="#2AC644" mr={3} />
        <Text color="white" fontSize="md">
          Chave de entrada
        </Text>
      </Flex>
    )}
  </Flex>
);

Header.propTypes = {
  kpiName: PropTypes.string,
  profile: PropTypes.string,
  isEntryKey: PropTypes.bool,
};

Header.defaultProps = {
  kpiName: '',
  profile: '',
  isEntryKey: false,
};

export default Header;
