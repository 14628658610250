/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgBoolean(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={3}
        y={6}
        width={14}
        height={8}
        rx={4}
        stroke={props.color}
        strokeWidth={2}
      />
      <rect
        x={12}
        y={9}
        width={2}
        height={2}
        rx={1}
        stroke={props.color}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgBoolean;
