import {
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { ProfileIcon } from './Icon';
import Breadcrumb from './Breadcrumb';
import { useAuth } from '../../hooks/useAuth';

const Header = () => {
  const { signOut } = useAuth();
  return (
    <Box
      as="header"
      position="absolute"
      top="0"
      bg="white"
      boxShadow="0px 1px 0px rgba(51, 51, 68, 0.07)"
      height={14}
      maxWidth="100vw"
      width="100%"
      paddingLeft={{
        base: 0,
        md: 14,
      }}
    >
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        pl={8}
      >
        <Box>
          <Breadcrumb />
        </Box>
        <Menu height="100%" width={18}>
          <MenuButton
            bg="transparent"
            height={14}
            width={14}
            borderLeft="1px solid #F0F0F9"
          >
            <Center>
              <ProfileIcon color="#3b3b3b" />
              <ChevronDownIcon color="grey.600" />
            </Center>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={signOut}>Log out</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default Header;
