import { Flex } from '@chakra-ui/react';

import LoginForm from './LoginForm';
import Header from './Header';
import Footer from './Footer';

const Main = () => {
  return (
    <Flex
      as="main"
      bg="white"
      width={{
        base: '95vw',
        md: '50vw',
      }}
      height={{ base: 'fit-content', md: '100%' }}
      margin={{
        base: '140px auto 40px auto',
        md: '0 0 0 50vw',
      }}
      zIndex="10"
      padding={{
        base: '20px 30px 30px',
        md: '8vh 30px 30px',
      }}
    >
      <Flex
        direction="column"
        height={{ base: '100%', md: '100%' }}
        maxWidth="450px"
        margin="0 auto"
      >
        <Header />
        <LoginForm />
        <Footer />
      </Flex>
    </Flex>
  );
};

export default Main;
