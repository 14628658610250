import PropTypes from 'prop-types';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

const ModalDeleteKpi = ({ isOpen, onCancel, onDelete }) => (
  <AlertDialog isOpen={isOpen} onClose={onCancel}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remover KPI
        </AlertDialogHeader>
        <AlertDialogBody>
          Você tem certeza? Essa alteração não pode ser revertida
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button colorScheme="red" onClick={() => onDelete()} ml={3}>
            Apagar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

ModalDeleteKpi.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ModalDeleteKpi;
