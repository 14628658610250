/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgBackwards(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.121 2v4.243h4.243M13.506 9.05l-3.429 3L7.506 9.8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.506 10.05a8 8 0 101.412-4.54"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgBackwards;
