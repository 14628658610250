import { Center, Text, Image, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Container = () => (
  <Center
    as="main"
    border="1px solid #F0F0F9"
    borderRadius="lg"
    margin={4}
    backgroundColor="white"
    width="100%"
    display="flex"
    flexDir="column"
    alignItems="center"
  >
    <Image
      maxW={40}
      maxH={40}
      objectFit="cover"
      src="/images/dashboard.svg"
      alt=""
    />
    <Text
      mb={3}
      as="h2"
      fontWeight="bold"
      fontSize="xl"
      textAlign="center"
      color="black"
    >
      Bem-vindo à Campanha de Incentivo
    </Text>
    <Text textAlign="center" fontSize="md" lineHeight={8} color="grey.200">
      Cadastre participantes para em seguida gerenciar
    </Text>
    <Text textAlign="center" fontSize="md" lineHeight={8} color="grey.200">
      as mecânicas da campanha
    </Text>
    <Button
      as={Link}
      borderColor="brand-main"
      borderRadius="3xl"
      borderWidth="2px"
      mt={8}
      to="/dashboard/acompanhamento"
      variant="outline"
    >
      Ir para Gestão de Participantes
    </Button>
  </Center>
);

export default Container;
