import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import api, { getRoles } from '../../services/api';

const FilterParticipants = ({
  openMenuFilter,
  setOpenMenuFilter,
  filter,
  setFilter,
  findParticipantsWithFilter,
}) => {
  const [roles, setRoles] = useState([]);
  const [regiao, setRegiao] = useState([]);
  const [subRegiao, setSubRegiao] = useState([]);

  const handleFilterChecked = e => {
    setFilter(prevState => ({
      ...prevState,
      status: {
        ...prevState.status,
        [e.target.value]: e.target.checked,
      },
    }));
  };

  const handleFilterInput = e => {
    setFilter(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const getAllRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.roles);
      setRegiao(response.regiao);
      setSubRegiao(response.subregiao);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <Drawer
      isOpen={openMenuFilter}
      placement="right"
      onClose={() => setOpenMenuFilter(false)}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <Box
          bg="#212121"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="1.5rem 1rem"
          color="#fff"
          w="full"
        >
          <DrawerHeader minWidth="30%">Filtrar lista</DrawerHeader>
          <DrawerCloseButton display="inline-flex" position="unset" />
        </Box>

        <DrawerBody p="2rem 2.5rem">
          <Box mb="1rem">
            <p style={{ marginBottom: '10px' }}>Status</p>
            <Stack spacing={8} direction="row">
              <Checkbox
                colorScheme="blackAlpha"
                onChange={handleFilterChecked}
                value="Confirmado"
                isChecked={!!filter.status?.Confirmado}
              >
                Confirmado
              </Checkbox>
              <Checkbox
                colorScheme="blackAlpha"
                onChange={handleFilterChecked}
                value="Pendente"
                isChecked={!!filter.status?.Pendente}
              >
                Pendente
              </Checkbox>
            </Stack>
          </Box>

          <Box>
            <p style={{ marginBottom: '10px' }}>Nome do Participante</p>
            <Input
              placeholder=""
              name="name"
              onChange={handleFilterInput}
              value={filter?.name}
            />
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            mt="1rem"
            style={{ gap: 20 }}
          >
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>E-mail</p>
              <Input
                placeholder=""
                name="email"
                onChange={handleFilterInput}
                value={filter?.email}
              />
            </Box>
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>Telefone</p>
              <Input
                placeholder=""
                name="phone"
                onChange={handleFilterInput}
                value={filter?.phone}
              />
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            mt="2rem"
            style={{ gap: 20 }}
          >
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>CPF</p>
              <Input
                placeholder=""
                name="cpf"
                onChange={handleFilterInput}
                value={filter?.cpf}
              />
            </Box>
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>CNPJ</p>
              <Input
                placeholder=""
                name="cnpj"
                onChange={handleFilterInput}
                value={filter?.cnpj}
              />
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            mt="2rem"
            style={{ gap: 20 }}
          >
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>Região</p>
              <Select
                placeholder="Todas"
                onChange={handleFilterInput}
                name="regiao"
              >
                {regiao.map(item => {
                  if (item.regiao === '') return null;
                  return (
                    <option
                      key={item.regiao}
                      value={item.regiao}
                      selected={filter?.regiao === item.regiao}
                    >
                      {item.regiao}
                    </option>
                  );
                })}
              </Select>
            </Box>
            <Box flex="1">
              <p style={{ marginBottom: '10px' }}>Sub Região</p>
              <Select
                placeholder="Todas"
                onChange={handleFilterInput}
                name="subregiao"
              >
                {subRegiao.map(item => {
                  if (item.subregiao === '') return null;
                  return (
                    <option
                      key={item.subregiao}
                      value={item.subregiao}
                      selected={filter?.subregiao === item.subregiao}
                    >
                      {item.subregiao}
                    </option>
                  );
                })}
              </Select>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            mt="2rem"
            style={{ gap: 20 }}
          >
            <Box flex="1" maxWidth="49%">
              <p style={{ marginBottom: '10px' }}>Perfil de Acesso</p>
              <Select
                placeholder="Todos"
                onChange={handleFilterInput}
                name="role"
              >
                {roles.map(role => (
                  <option
                    key={role.id}
                    value={role.id}
                    selected={filter?.role === role.id}
                  >
                    {role.name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </DrawerBody>

        <DrawerFooter>
          <Button
            style={{
              backgroundColor: '#212121',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '100px',
              minWidth: '400px',
            }}
            onClick={findParticipantsWithFilter}
          >
            Aplicar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default FilterParticipants;
