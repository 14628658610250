import PropTypes from 'prop-types';
import { Center, Button, Box, Text, Flex } from '@chakra-ui/react';

import { RoundedPlusIcon } from '../shared/Icon';

const EmptyCard = ({ onClick }) => (
  <Center
    w="100%"
    maxW="300px"
    height="450px"
    borderWidth="2px"
    borderStyle="dashed"
    borderColor="grey.600"
    borderRadius="lg"
  >
    <Flex
      as={Button}
      onClick={onClick}
      flexDir="column"
      height="145px"
      bg="transparent"
      _hover={{ bg: 'transparent' }}
    >
      <Box as={RoundedPlusIcon} stroke="brand-main" />
      <Text mt={8}>Adicionar etapa</Text>
    </Flex>
  </Center>
);

EmptyCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EmptyCard;
