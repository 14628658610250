/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgArrowright(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6l4 4-4 4M16 10H4"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowright;
