import { Flex, Button, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useKpi } from '../../hooks/useKpi';

const Footer = () => {
  const history = useHistory();
  const { onSubmit } = useKpi();

  return (
    <Flex height="60px" borderTop="1px solid #F0F0F9" justifyContent="flex-end">
      <Flex marginY="auto" mr={8} alignItems="center">
        <Text
          textAlign="center"
          cursor="pointer"
          fontWeight="bold"
          fontSize="md"
          onClick={() => history.go(0)}
        >
          Descartar Alterações
        </Text>
        <Button
          ml={8}
          px={8}
          borderRadius="3xl"
          color="white"
          bg="brand-main"
          onClick={onSubmit}
          _hover={{
            bg: 'brand-brighter',
          }}
        >
          Salvar Alterações
        </Button>
      </Flex>
    </Flex>
  );
};

export default Footer;
