import { useState, useEffect, useRef } from 'react';
import { Text, Box, useToast } from '@chakra-ui/react';

import ImportCard from '../components/CampaignImport/ImportCard';
import ImportList from '../components/CampaignImport/ImportList';
import ImportProgress from '../components/CampaignImport/ImportProgress';
import api, {
  getImportList,
  postCampaignImport,
  getSheetExample,
  getReport,
  getImportLogStatus,
} from '../services/api';

const CampaignImport = () => {
  const [isLoading, setLoading] = useState(false);
  const [isFetchingList, setFetchingList] = useState(false);
  const [file, setFile] = useState(null);
  const [importList, setImportList] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [fetchingProgress, setFetchingProgress] = useState(false);
  const [importId, setImportId] = useState(null);
  const inputFileRef = useRef(null);
  const toast = useToast();

  const fetchImportList = async () => {
    try {
      setFetchingList(true);
      const data = await getImportList();
      setImportList(data);
    } finally {
      setFetchingList(false);
    }
  };

  useEffect(() => {
    fetchImportList();
  }, []);

  const onChange = event => {
    if (event.target?.files[0]) {
      setFile(event.target?.files[0]);
    }
  };

  const onSubmit = async () => {
    toast({
      position: 'top-right',
      title: 'Importando...',
      description: 'Estamos importando sua planilha.',
      status: 'info',
      isClosable: true,
    });
    setImportProgress(0);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('originalname', file.name);
      const { importLog } = await postCampaignImport({ formData });
      toast.closeAll();
      toast({
        position: 'top-right',
        title: 'Sucesso!.',
        description: 'Planilha importada com sucesso.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      // setFile(null);
      inputFileRef.current.value = '';
      setImportId(importLog.id);
      setFetchingProgress(true);
    } catch (e) {
      toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExample = async () => {
    try {
      await api.get('/admin/exports/participants');
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const handleGetReport = async id => {
    try {
      const data = await getReport({ id });
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${id}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const fetchImportProgress = async () => {
    try {
      const { total_rows: totalRows, processed_rows: processedRows } =
        await getImportLogStatus(importId);
      const percentage = Math.round((processedRows / totalRows) * 100);
      setImportProgress(percentage);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (importId && !fetchingProgress && importProgress < 100) {
        setFetchingProgress(true);
        fetchImportProgress();
      }
    }, 2500);

    if (importProgress === 100) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [importId]);

  useEffect(() => {
    if (importProgress === 100) {
      setImportId(null);
      fetchImportList();
    }
  }, [importProgress]);

  return (
    <Box
      border="1px solid #F0F0F9"
      borderRadius="lg"
      margin={4}
      backgroundColor="white"
      width="100%"
      paddingX={8}
      paddingY={10}
    >
      <Text as="h2" fontSize="2xl" fontWeight="bold" color="grey.500" mb={3}>
        Atualize o banco de dados de participantes da campanha
      </Text>
      {fetchingProgress ? (
        <ImportProgress
          fileName={file?.name}
          percentage={importProgress}
          setFetchingProgress={setFetchingProgress}
        />
      ) : (
        <ImportCard
          loading={isLoading}
          fileName={file?.name}
          onChange={onChange}
          onSubmit={onSubmit}
          handleSheetModel={handleDownloadExample}
          inputFileRef={inputFileRef}
        />
      )}

      <Box mt={10}>
        <Text as="h2" fontSize="md" fontWeight="bold" color="grey.500" mb={10}>
          Status das últimas importações
        </Text>
        <ImportList
          onClickItem={handleGetReport}
          importList={importList}
          isLoading={isFetchingList}
        />
      </Box>
    </Box>
  );
};

export default CampaignImport;
