import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  Button,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
  Center,
  FormLabel,
  Flex,
  Text,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon, XIcon } from './Icon';

registerLocale('ptBR', ptBR);

const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const {
    onOpen: onOpenPopover,
    onClose: onClosePopover,
    isOpen: isPopoverOpen,
  } = useDisclosure();

  const shouldShowPlaceHolder = !startDate || !endDate;

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onClosePopover();
    }
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpen={onOpenPopover}
      onClose={onClosePopover}
      placement="bottom-end"
      closeOnBlur={false}
    >
      <PopoverTrigger position="relative">
        <Box>
          <FormLabel fontWeight="bold" color="grey.200">
            Data de Início e Data de Término
          </FormLabel>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            h={10}
            border="1px solid #DDDDEE"
            borderRadius="md"
            paddingX={4}
          >
            <Box>
              {shouldShowPlaceHolder ? (
                <Text>Escolha a data inicial e final</Text>
              ) : (
                <Text>
                  {`${format(startDate, 'dd/MM/yyyy')} - ${format(
                    endDate,
                    'dd/MM/yyyy',
                  )}`}
                </Text>
              )}
            </Box>
            <Box as={CalendarIcon} stroke="brand-main" />
          </Flex>
        </Box>
      </PopoverTrigger>
      <Button
        stroke="grey.200"
        position="absolute"
        right={20}
        onClick={resetDates}
        bg="transparent"
        transform="translateY(-100%)"
        _hover={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
        _focus={{ outline: '0' }}
      >
        <Center as={XIcon} stroke="grey.200" />
      </Button>
      <PopoverContent p="0" w="245px" h="auto">
        <PopoverBody p="0">
          <DatePicker
            tabIndex="0"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            locale="ptBR"
            selectsRange
            inline
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.bool,
  endDate: PropTypes.bool,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
};

export default DateRangePicker;
