import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableCellText,
  TableHead,
  TableHeader,
  TableRow,
} from '../shared/DataTable';

import Header from './Header';
import Pagination from './Pagination';
import FilterParticipants from './FilterParticipants';
import EmptyTable from './EmptyTable';
import ModalExportParticipants from './ModalExportParticipants';

const ParticipantsTable = ({
  headers,
  participants,
  handleSelectParticipants,
  onChange,
  currentPage,
  count,
  openMenuFilter,
  setOpenMenuFilter,
  countAllParticipants,
  filter,
  setFilter,
  findParticipantsWithFilter,
  format,
  setFormat,
  openModal,
  setOpenModal,
  exportParticipants,
}) => {
  console.log(participants);

  return (
    <Center
      border="1px solid #F0F0F9"
      borderRadius="lg"
      margin={4}
      backgroundColor="white"
      maxWidth="calc(100% - 30px)"
      width="100%"
      height="100%"
      flexDir="column"
      as={Flex}
      alignItems="center"
    >
      <FilterParticipants
        openMenuFilter={openMenuFilter}
        setOpenMenuFilter={setOpenMenuFilter}
        filter={filter}
        setFilter={setFilter}
        findParticipantsWithFilter={findParticipantsWithFilter}
      />
      <ModalExportParticipants
        format={format}
        setFormat={setFormat}
        openModal={openModal}
        setOpenModal={setOpenModal}
        exportParticipants={exportParticipants}
      />
      <Header
        openMenuFilter={openMenuFilter}
        setOpenMenuFilter={setOpenMenuFilter}
        countParticipants={countAllParticipants}
        setOpenModal={setOpenModal}
      />
      {count > 0 ? (
        <Box
          overflowX="scroll"
          overflowY="hidden"
          maxW="100%"
          position="relative"
        >
          <Table>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableHeader>{header}</TableHeader>
                ))}
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map(participant => (
                <TableRow bg="white">
                  <TableCell>
                    <Center>
                      <Checkbox
                        onChange={({ target }) =>
                          handleSelectParticipants({
                            participant,
                            isChecked: target.checked,
                          })
                        }
                      />
                    </Center>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.status?.name || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>{participant.name || '-'}</TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant.fantasy_name || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.superior?.name || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.unique_id?.length <= 11
                        ? participant.unique_id
                        : '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.unique_id?.length > 11
                        ? participant.unique_id
                        : '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.participantRole?.name || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.address?.street || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.address?.number || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.address?.complement || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.address?.zipcode || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.address?.city || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.neighborhood ||
                        participant?.address?.neighborhood ||
                        '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.state || participant?.address?.state || '-'}
                    </TableCellText>
                  </TableCell>
                  <TableCell>
                    <TableCellText>
                      {participant?.participantRole?.name || '-'}
                    </TableCellText>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <EmptyTable />
      )}
      <Pagination onChange={onChange} currentPage={currentPage} count={count} />
    </Center>
  );
};

ParticipantsTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelectParticipants: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  openMenuFilter: PropTypes.bool.isRequired,
};

ParticipantsTable.defaultProps = {
  headers: [
    '',
    'Status',
    'Nome do Participante',
    'Nome Fantasia',
    'superior',
    'Cpf',
    'Cnpj',
    'Nível',
    'Endereço',
    'Numero',
    'Complemento',
    'CEP',
    'Cidade',
    'Bairro',
    'Estado',
    'Nivel',
  ],
};

export default ParticipantsTable;
