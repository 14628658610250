/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgFilter(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 5h12M6 9h8M8 13h4"
      />
    </svg>
  );
}

export default SvgFilter;
