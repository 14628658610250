/* eslint-disable */
import ProptTypes from 'prop-types';
import React, { useEffect, createContext, useState, useContext } from 'react';
import { useToast } from '@chakra-ui/react';

import {
  getEquations,
  getVariablesCampaign,
  postEquations,
} from '../services/api';

const KpiContext = createContext();

const initialEquationList = [
  {
    id: 0,
    currentValue: '',
    prefix: '',
    equation: [],
    description: '',
  },
];

const initialPrizeList = [
  {
    type: 'calc',
    currentValue: '',
    equation: [],
    ranking_list: [
      {
        prizeType: '',
        prize: [],
        prizeCurrentValue: '',
        positionType: '',
        position: [],
        positionCurrentValue: '',
      },
    ],
    tiebreaker: [''],
  },
];

const operators = [
  '+',
  '-',
  '*',
  '/',
  '(',
  ')',
  '==',
  // 'Igual á',
  '!=',
  // 'Diferente de',
  '>',
  '<',
  '>=',
  '<=',
];

export const KpiProvider = ({ children, kpiId, kpi }) => {
  const toast = useToast();
  const [equationList, setEquationList] = useState(initialEquationList);
  const [prizeList, setPrizeList] = useState(initialPrizeList);
  const [variableList, setVariableList] = useState([]);
  const autoCompleteValues = [
    ...variableList,
    ...operators,
    // ...[100, 200, 300],
  ];

  useEffect(() => {
    async function getEquation() {
      try {
        const data = await getEquations(kpiId);
        if (data?.kpiVariable?.length) {
          setVariableList(data.kpiVariable.map(item => item.name));
        }
        if (data?.kpiEquation?.length) {
          setEquationList(
            data.kpiEquation.map(item => ({
              id: item.id,
              currentValue: '',
              prefix: item.prefix,
              equation: item.equation.split(' ').filter(i => i),
              description: item.description,
            })),
          );
        }
        if (data?.kpiPrize?.length) {
          setPrizeList(
            data.kpiPrize.map(item => ({
              id: item.id,
              currentValue: '',
              type: item.type,
              tiebreaker: item.tiebreaker,
              ranking_list: item.ranking_list.map(rankingItem => ({
                prizeType: '',
                prize: rankingItem.prize,
                prizeCurrentValue: '',
                positionType: rankingItem.positionType,
                position: rankingItem.position,
                positionCurrentValue: '',
              })),
              equation: item.equation.split(' ').filter(i => i),
            })),
          );
        }
      } catch (error) {
        toast({
          position: 'top-right',
          title: 'Erro de conexão.',
          description: 'Por favor, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getEquation();
  }, [kpiId, toast]);

  const handleAddEquation = () =>
    setEquationList(oldEquationList => [
      ...oldEquationList,
      {
        id: oldEquationList.length,
        prefix: 'E',
        equation: [],
      },
    ]);

  const handleRemoveEquation = id =>
    setEquationList(oldEquationList =>
      oldEquationList.filter(item => item.id !== id),
    );

  const handleRemoveVariable = index => {
    const variableValue = variableList[index];

    const existInEquations = equationList
      .reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue.equation,
        ],
        [],
      )
      .some(item => item === variableValue);

    const existInPrizes = JSON.stringify(prizeList).includes(
      `"${variableValue}"`,
    );

    if (!existInEquations && !existInPrizes) {
      return setVariableList(
        variableList.filter(item => item !== variableValue),
      );
    }

    return toast({
      position: 'top-right',
      title: 'Variável utilizada.',
      description: 'Por favor, remova o uso dessa variável para continuar.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleAddPrize = () =>
    setPrizeList(oldPrizeList => [
      ...oldPrizeList,
      {
        id: oldPrizeList.length,
        type: 'calc',
        currentValue: '',
        equation: [],
        ranking_list: [
          {
            prizeType: '',
            prize: [],
            prizeCurrentValue: '',
            positionType: '',
            position: [],
            positionCurrentValue: '',
          },
        ],
        tiebreaker: [''],
      },
    ]);

  const onEquationChange = (value, index) => {
    console.log('Equation Change', value, index);
    setEquationList(oldEquationList =>
      oldEquationList.map((e, i) => {
        if (i === index) {
          console.log(
            e,
            i,
            value.replace(/[^A-Z0-9_+-/()=!><*]/gi, '').toLowerCase(),
          );
          return {
            ...e,
            currentValue: value
              .replace(/[^A-Z0-9_+-/()=!><*]/gi, '')
              .toLowerCase(),
          };
        }
        return e;
      }),
    );

    console.log('Equation List', equationList);
  };

  const onEquationDescriptionChange = (value, index) => {
    setEquationList(oldEquationList =>
      oldEquationList.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            description: value,
          };
        }
        return e;
      }),
    );
  };

  const onEquationConfirm = (value, position) => {
    const isValid =
      autoCompleteValues.some(item => item === value) || Number(value);
    if (isValid) {
      setEquationList(oldEquationList =>
        oldEquationList.map((e, index) =>
          position === index
            ? { ...e, currentValue: '', equation: [...e.equation, value] }
            : e,
        ),
      );
    }
  };

  const onPrizeChange = (value, index) =>
    setPrizeList(oldPrizeList =>
      oldPrizeList.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            currentValue: value
              .replace(/[^A-Z0-9_+-/()=!><*]/gi, '')
              .toLowerCase(),
          };
        }
        return e;
      }),
    );

  const onPrizeConfirm = (value, position) => {
    const isValid = autoCompleteValues.some(item => item === value);

    const isNumber = Number(value);

    console.log(autoCompleteValues, value, isValid, isNumber);

    if (isValid || isNumber) {
      setPrizeList(oldPrizeList =>
        oldPrizeList.map((e, index) =>
          position === index
            ? { ...e, currentValue: '', equation: [...e.equation, value] }
            : e,
        ),
      );
    }
  };

  const onEquationKeyDown = (code, value, position) => {
    if (code === 'Enter' || code === 'Space') {
      onEquationConfirm(value, position);
    }
    if (code === 'Backspace' && value === '') {
      setEquationList(
        equationList.map((e, index) =>
          index === position
            ? { ...e, equation: e.equation.splice(0, e.equation.length - 1) }
            : e,
        ),
      );
    }
  };

  const onPrizeKeyDown = (code, value, position) => {
    if (code === 'Enter' || code === 'Space') {
      onPrizeConfirm(value, position);
    }
    if (code === 'Backspace' && value === '') {
      setPrizeList(
        prizeList.map((e, index) =>
          index === position
            ? { ...e, equation: e.equation.splice(0, e.equation.length - 1) }
            : e,
        ),
      );
    }
  };

  const onPrizeTypeSelect = (value, position) =>
    setPrizeList(
      prizeList.map((e, index) =>
        index === position ? { ...e, type: value } : e,
      ),
    );

  const onConditionChange = (value, position) =>
    setEquationList(
      equationList.map((equation, index) =>
        index === position ? { ...equation, prefix: value } : equation,
      ),
    );

  const onUpdatePrize = (tiebreakerList, rankingList, positionEdit) => {
    console.log('On Update Prize', tiebreakerList, rankingList, positionEdit);

    setPrizeList(
      prizeList.map((prizeItem, index) =>
        positionEdit === index
          ? {
              ...prizeItem,
              tiebreaker: tiebreakerList.map(t => t.tiebreaker),
              ranking_list: rankingList,
            }
          : prizeItem,
      ),
    );
  };

  useEffect(() => {
    async function getVariables() {
      if (!kpi?.campaignStep?.id) return;

      console.log(kpi);

      try {
        const data = await getVariablesCampaign(kpi?.campaignStep?.id);
        console.log(data);
        setVariableList(data.map(item => item.slug));
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }

    getVariables();
  }, [kpi]);

  async function onSubmit() {
    console.log('Prize List', prizeList);

    const data = {
      kpiId,
      variables: variableList,
      prizes: prizeList.map(prizeItem => {
        return {
          ...prizeItem,
          equation: prizeItem.equation.join(' '),
          ranking_list: prizeItem.ranking_list.map(rankingItem => {
            console.log('Ranking Item', rankingItem);
            return {
              prizeType: rankingItem.prizeType,
              prize: [rankingItem.prize[0] ?? rankingItem.prizeCurrentValue],
              positionType: rankingItem.positionType,
              position: [
                rankingItem.position.flat() ?? rankingItem.positionCurrentValue,
              ],
            };
          }),
        };
      }),
      equations: equationList,
    };

    console.log('Data Post', data);

    try {
      await postEquations(data);
      return toast({
        position: 'top-right',
        title: 'Dados salvos!',
        description: 'Informações do KPI salvas com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      return toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <KpiContext.Provider
      value={{
        variableList,
        autoCompleteValues,
        equationList,
        prizeList,
        setVariableList,
        handleAddEquation,
        handleRemoveEquation,
        handleAddPrize,
        onEquationChange,
        onEquationConfirm,
        onPrizeChange,
        onPrizeConfirm,
        onEquationKeyDown,
        onPrizeKeyDown,
        onPrizeTypeSelect,
        onConditionChange,
        onUpdatePrize,
        onSubmit,
        handleRemoveVariable,
        onEquationDescriptionChange,
      }}
    >
      {children}
    </KpiContext.Provider>
  );
};

export const useKpi = () => {
  const context = useContext(KpiContext);

  if (!context) {
    throw new Error('useKpi must be used within a KpiProvider');
  }

  return context;
};

KpiProvider.propTypes = {
  children: ProptTypes.node.isRequired,
  kpiId: ProptTypes.string.isRequired,
};
