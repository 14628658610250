import { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import { useAuth } from '../../hooks/useAuth';

const LoginStep = () => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: { uniqueId: '', password: '' },
  });

  async function onSubmit(data) {
    const { username, password } = data;
    // setLoading(true);
    await signIn({ username, password });
    // setLoading(false);
  }

  return (
    <>
      <Box mb={16}>
        <Text as="small" fontSize="xl" color="brand-main" fontWeight="bold">
          Seja bem-vindo à Campanha de Incentivo
        </Text>
        <Heading
          as="h1"
          lineHeight="normal"
          fontSize={{ base: '4xl', md: '48px' }}
          color="brand-main"
        >
          Plataforma PAT
        </Heading>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="username" mb="32px" isInvalid={errors.username}>
          <FormLabel fontWeight="bold" color="grey.200">
            Usuário
          </FormLabel>
          <Input
            ref={register}
            name="username"
            type="text"
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="unique_id" mb="32px" isInvalid={errors.password}>
          <FormLabel fontWeight="bold" color="grey.200">
            Senha
          </FormLabel>
          <Input
            ref={register}
            name="password"
            type="password"
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <Flex justify="flex-end">
          <Button
            size="lg"
            width="100%"
            type="submit"
            variant="brand-brighter"
            isLoading={loading}
            rightIcon={<ArrowForwardIcon />}
          >
            <Text fontSize="md">Continuar</Text>
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default LoginStep;
