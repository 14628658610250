/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgCalendar(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={3}
        y={4}
        width={14}
        height={13}
        rx={2}
        stroke={props.color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path stroke={props.color} strokeWidth={2} d="M3 9h14" />
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        d="M7 5V2M13 5V2"
      />
    </svg>
  );
}

export default SvgCalendar;
