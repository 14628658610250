import PropTypes from 'prop-types';
import { Center, Flex, Text, Image, Button } from '@chakra-ui/react';

const EmptyState = ({ handleAddKpi }) => (
  <Flex border="2px dashed #DDDDEE" flexDir="column" mx={4} my={10} maxW="100%">
    <Center as={Flex} flexDir="column" mb={10} mt={16}>
      <Image
        alt=""
        height={48}
        objectFit="cover"
        src="/images/people-sheet.svg"
        width={48}
      />
      <Text fontWeight="bold" fontSize="xl" mt={8}>
        Nenhuma KPI adicionada
      </Text>
      <Text fontSize="md" mt={4}>
        Adicione KPIs para formular essa etapa
      </Text>
      <Button
        borderColor="brand-main"
        borderRadius="3xl"
        borderWidth="2px"
        mt={8}
        onClick={handleAddKpi}
        px={14}
        variant="outline"
      >
        Adicionar Kpi
      </Button>
    </Center>
  </Flex>
);

EmptyState.propTypes = {
  handleAddKpi: PropTypes.func.isRequired,
};

export default EmptyState;
