import { Flex, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Header from './Header';
import Navbar from './Navbar';

const DashboardLayout = ({ children }) => (
  <Box minHeight="100vh" height="100%" maxWidth="100vw">
    <Flex
      bg="grey.50"
      minHeight="100vh"
      height="100%"
      maxWidth="100%"
      margin="auto"
      paddingLeft={{
        base: 0,
        md: 14,
      }}
      paddingTop={14}
      paddingBottom={{
        base: 14,
        md: 0,
      }}
    >
      {children}
    </Flex>
    <Header />
    <Navbar />
  </Box>
);

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
