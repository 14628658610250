/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgBlock(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10} cy={10} r={7} stroke={props.color} strokeWidth={2} />
      <path d="M5 5l10 10" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}

export default SvgBlock;
