import { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import Sidebar from '../components/KpiRules/Sidebar';
import Header from '../components/KpiRules/Header';
import Main from '../components/KpiRules/Main';
import Footer from '../components/KpiRules/Footer';
import { getOneKpi, getVariablesCampaign } from '../services/api';
import { KpiProvider } from '../hooks/useKpi';

const KpiEdit = () => {
  const { kpiId } = useParams();
  const [kpi, setKpi] = useState(null);

  useEffect(() => {
    async function getKpi() {
      try {
        const data = await getOneKpi({ kpiId });
        setKpi(data);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }
    getKpi();
  }, [kpiId]);

  return (
    <Flex
      backgroundColor="#212121"
      border="1px solid #F0F0F9"
      borderRadius="lg"
      flexDir="column"
      margin={4}
      width="100%"
    >
      <KpiProvider kpiId={kpiId} kpi={kpi}>
        <Header
          isEntryKey={kpi?.is_entry_key}
          kpiName={kpi?.name}
          profile={kpi?.participantRole?.name}
        />
        <Flex
          bg="white"
          borderRadius="lg"
          flexDir="column"
          height="100%"
          width="100%"
        >
          <Flex flex="1">
            <Sidebar />
            <Main />
          </Flex>
          <Footer />
        </Flex>
      </KpiProvider>
    </Flex>
  );
};

export default KpiEdit;
