/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgMoreoptions(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={6}
        cy={10}
        r={0.5}
        transform="rotate(-90 6 10)"
        stroke={props.color}
      />
      <circle
        cx={10}
        cy={10}
        r={0.5}
        transform="rotate(-90 10 10)"
        stroke={props.color}
      />
      <circle
        cx={14}
        cy={10}
        r={0.5}
        transform="rotate(-90 14 10)"
        stroke={props.color}
      />
    </svg>
  );
}

export default SvgMoreoptions;
