const Button = {
  baseStyle: {
    lineHeight: '1.2',
    borderRadius: 'md',
    fontWeight: 'semibold',
    _focus: {
      boxShadow: 'outline',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {
      _disabled: {
        bg: 'initial',
      },
    },
  },
  variants: {
    unstyled: {
      bg: 'none',
      color: 'inherit',
      display: 'inline',
      lineHeight: 'inherit',
      m: 0,
      p: 0,
    },
    'brand-brighter': {
      transition: 'all 0.3s ease-in-out',
      color: '#fff',
      bg: 'brand-brighter',
      paddingX: '24px',
      _hover: {
        backgroundColor: 'brand-main',
      },
    },
  },
  sizes: {
    lg: {
      h: 12,
      minW: 12,
      fontSize: 'lg',
      px: 6,
      height: '42px',
    },
    md: {
      h: 10,
      minW: 10,
      fontSize: 'md',
      px: 4,
    },
    sm: {
      h: 8,
      minW: 8,
      fontSize: 'sm',
      px: 3,
    },
    xs: {
      h: 6,
      minW: 6,
      fontSize: 'xs',
      px: 2,
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'gray',
  },
};

export default Button;
