/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgExport(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 11h.5a2 2 0 002-2V5a2 2 0 00-2-2H6a2 2 0 00-2 2v4a2 2 0 002 2h.5M12.5 14L10 16.5 7.5 14M10 16V8"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgExport;
