import { Box, Button, Flex, Image, Progress, Text } from '@chakra-ui/react';

const ImportProgress = ({ fileName, percentage, setFetchingProgress }) => {
  return (
    <Box border="2px solid #F0F0F9" maxW="630px" padding={8} borderRadius="md">
      <Flex alignItems="center" mb={8}>
        <Image
          width={24}
          height={24}
          objectFit="cover"
          src="/images/people-sheet.svg"
          alt=""
        />
        <Flex marginLeft={8} flexDir="column">
          <Text as="h3" fontSize="md" color="black" fontWeight="bold" mb={3}>
            Planilha de Participantes
          </Text>
        </Flex>
      </Flex>

      <Box
        border="1px solid #dedede"
        borderRadius={4}
        borderBottom="1px solid transparent"
        p="1rem 0 0 0"
        m="1rem 0 2rem 0"
      >
        <Flex
          px="1rem"
          alignItems="center"
          mb="0.8rem"
          justifyContent="space-between"
        >
          <Text color="#444">
            <b>Processando</b>
            <span style={{ fontSize: '0.8rem', color: '#d3d1d1' }}>
              {` ${fileName}`}
            </span>
          </Text>
          <Text px="1rem" mb="5px" color="#5072f4" fontWeight="700">
            {` ${percentage} %`}
          </Text>
        </Flex>

        <Progress value={percentage} size="xs" colorScheme="purple" />
      </Box>
      {percentage === 100 && (
        <Button
          w="100%"
          bg="black"
          color="white"
          borderRadius="3xl"
          _hover={{ bg: 'brand-brighter' }}
          onClick={() => setFetchingProgress(false)}
        >
          Nova Importação
        </Button>
      )}
    </Box>
  );
};

export default ImportProgress;
