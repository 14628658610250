import { Link } from 'react-router-dom';
import {
  Flex,
  Text,
  Stat,
  StatLabel,
  StatHelpText,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { FunnelSimple, File } from 'phosphor-react';
import MiniBadge from '../shared/MiniBadge';

const Header = ({
  openMenuFilter,
  setOpenMenuFilter,
  countParticipants,
  setOpenModal,
}) => (
  <Flex
    width="100%"
    height={112}
    borderBottom="1px solid #F0F0F9"
    padding={8}
    justifyContent="space-between"
  >
    <Flex alignItems="center">
      <Text fontSize="5xl" color="#2AC644">
        {((countParticipants.accepted / countParticipants.total) * 100).toFixed(
          3,
        )}
        %
      </Text>
      <Text maxW={40} color="grey.200" ml={4} fontSize="sm">
        {countParticipants.accepted}
        {' de '}
        {countParticipants.total}
        {' participantes já aderiram'}
      </Text>
      <Stat ml={14}>
        <StatLabel fontSize="12px" color="grey.200">
          Confirmado
        </StatLabel>
        <StatHelpText fontSize="lg" color="grey.300" w={20}>
          <MiniBadge mr={3} color="#2AC644" />
          {countParticipants.accepted}
        </StatHelpText>
      </Stat>
      <Stat ml={14}>
        <StatLabel fontSize="12px" color="grey.200">
          Pendente
        </StatLabel>
        <StatHelpText fontSize="lg" color="grey.300" w={20}>
          <MiniBadge mr={3} />
          {countParticipants.pending}
        </StatHelpText>
      </Stat>
    </Flex>
    <ButtonGroup gap={2}>
      <Link
        to="/dashboard/importacao"
        style={{ ...style.button, ...style.buttonOutline }}
        onClick={() => setOpenModal(true)}
      >
        Importar participantes
        <File size={24} weight="bold" />
      </Link>

      <Button
        style={{ ...style.button, ...style.buttonOutline }}
        onClick={() => setOpenModal(true)}
      >
        Exportar
        <File size={24} weight="bold" />
      </Button>

      <Button style={style.button} onClick={() => setOpenMenuFilter(true)}>
        Filtrar
        <FunnelSimple size={24} weight="bold" />
      </Button>
    </ButtonGroup>
  </Flex>
);

const style = {
  button: {
    background: '#8112ae',
    borderRadius: '100px',
    border: '2px solid #8112ae',
    color: '#fff',
    padding: '.5rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },

  buttonOutline: {
    background: 'transparent',
    border: '2px solid #8112ae',
    color: '#8112ae',
  },
};

export default Header;
