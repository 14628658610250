import { Flex, Text, /* Button, */ Center, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { isBefore, isAfter, format } from 'date-fns';
import { Link } from 'react-router-dom';

import {
  // PeopleIcon,
  CheckCircledIcon,
  ClockIcon,
  CalendarIcon,
  ArrowRightIcon,
} from '../shared/Icon';

const Card = ({ name, id, endDate, startDate }) => {
  const status = getStatus(startDate, endDate);
  const bg = statusColor[status];
  const StatusIcon = statusIcon[status];

  return (
    <Flex
      w="100%"
      maxW="300px"
      height="450px"
      bg={bg}
      justifyContent="space-between"
      borderRadius="lg"
      border="1px solid #F0F0F9"
      flexDir="column"
      position="relative"
    >
      <Flex flexDir="column" p={8}>
        <Text
          as="h3"
          fontWeight="bold"
          fontSize="4xl"
          color={status === 'active' ? 'white' : 'grey.300'}
        >
          {name}
        </Text>
        <Text color="grey.300" mt={4} fontSize="sm">
          {`${format(new Date(startDate), 'dd/MM/yyyy')} à ${format(
            new Date(endDate),
            'dd/MM/yyyy',
          )}`}
        </Text>
      </Flex>
      <Flex
        height="55%"
        bg="white"
        width="100%"
        borderRadius="md"
        borderTop="1px solid #F0F0F9"
        position="relative"
      >
        <Flex flexDir="column" width="100%" justifyContent="space-between">
          <Box padding={8}>
            <Text color="grey.200">Chave de entrada Sell Out</Text>
          </Box>
          {/* <Flex
            marginX={4}
            height={16}
            justifyContent={
              status === 'incoming' ? 'space-between' : 'flex-end'
            }
            borderTop="1px solid #F0F0F9"
          >
            {status === 'incoming' && (
              <Button marginY="auto" bg="transparent" border="none">
                <PeopleIcon color="#000" />
                <Text ml={3}>Excluir</Text>
              </Button>
            )}
            <Button marginY="auto" bg="transparent" border="none">
              <PeopleIcon color="#000" />
              <Text ml={3}>Duplicar</Text>
            </Button>
          </Flex> */}
        </Flex>
        <Center
          borderRadius="50%"
          w="50px"
          h="50px"
          bg="brand-highlight"
          position="absolute"
          right={4}
          transform="translateY(-50%)"
          as={Link}
          to={`/dashboard/etapa/${id}`}
        >
          <Box as={ArrowRightIcon} stroke="brand-main" />
        </Center>
      </Flex>
      <Box
        as={StatusIcon}
        stroke={status === 'active' ? 'brand-highlight' : 'grey.200'}
        position="absolute"
        top={4}
        right={4}
      />
    </Flex>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};

const getStatus = (startDate, endDate) => {
  const today = new Date();

  if (isBefore(today, new Date(startDate))) {
    return 'incoming';
  }

  if (isAfter(today, new Date(endDate))) {
    return 'expired';
  }

  return 'active';
};

const statusColor = {
  active: 'brand-main',
  expired: 'grey.50',
  incoming: 'white',
};

const statusIcon = {
  active: ClockIcon,
  expired: CheckCircledIcon,
  incoming: CalendarIcon,
};

export default Card;
