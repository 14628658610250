import { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  Button,
  Flex,
} from '@chakra-ui/react';
import { DownloadSimple } from 'phosphor-react';

const ModalExportParticipants = ({
  format,
  setFormat,
  openModal,
  setOpenModal,
  exportParticipants,
}) => {
  const ref = useRef();

  return (
    <AlertDialog
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
      leastDestructiveRef={ref}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Exportar lista de Participantes
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <hr />

          <AlertDialogBody>
            <Flex
              justifyContent="space-around"
              alignItems="center"
              marginY="1rem"
            >
              <Button
                onClick={() => setFormat('xlsx')}
                style={
                  format === 'xlsx'
                    ? {
                        backgroundColor: '#eae5e5',
                        color: '#333',
                        border: '1px solid #b00dcc',
                      }
                    : {
                        backgroundColor: '#fff',
                        color: '#333',
                      }
                }
              >
                .xlsx
              </Button>
              <Button
                onClick={() => setFormat('xls')}
                style={
                  format === 'xls'
                    ? {
                        backgroundColor: '#eae5e5',
                        color: '#333',
                        border: '1px solid #b00dcc',
                      }
                    : {
                        backgroundColor: '#fff',
                        color: '#333',
                      }
                }
              >
                .xls
              </Button>
              <Button
                onClick={() => setFormat('csv')}
                style={
                  format === 'csv'
                    ? {
                        backgroundColor: '#eae5e5',
                        color: '#333',
                        border: '1px solid #b00dcc',
                      }
                    : {
                        backgroundColor: '#fff',
                        color: '#333',
                      }
                }
              >
                .csv
              </Button>
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              style={{ backgroundColor: '#b00dcc', color: '#fff', gap: 10 }}
              w="full"
              onClick={exportParticipants}
              ml={3}
              display="flex"
              alignItems="center"
            >
              Exportar Lista
              <DownloadSimple size={24} weight="bold" />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ModalExportParticipants;
