/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgPeople(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={10.184}
        cy={7.345}
        rx={2.294}
        ry={2.345}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M13.89 13.699s-1.66 1.3-3.706 1.3c-2.047 0-3.706-1.3-3.706-1.3 0-2.669 1.66-3.93 3.706-3.93 2.047 0 3.706 1.261 3.706 3.93z"
        stroke={props.color}
        strokeWidth={2}
      />
      <ellipse
        cx={16.261}
        cy={6.942}
        rx={1.696}
        ry={1.733}
        stroke={props.color}
        strokeWidth={1.79}
      />
      <path
        d="M16.261 12.6c1.513 0 2.74-.962 2.74-.962 0-1.973-1.227-2.905-2.74-2.905"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={3.739}
        cy={6.942}
        rx={1.696}
        ry={1.733}
        stroke={props.color}
        strokeWidth={1.79}
      />
      <path
        d="M3.74 12.6c-1.514 0-2.74-.962-2.74-.962 0-1.973 1.226-2.905 2.74-2.905"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgPeople;
