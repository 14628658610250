import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Image,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableCellText,
  TableHead,
  TableHeader,
  TableRow,
} from '../shared/DataTable';

import Header from './Header';
import Pagination from './Pagination';
import FilterParticipants from './FilterParticipants';

const EmptyTable = () => (
  <Center w="full" m="4rem">
    <Flex alignItems="center" direction="column">
      <Image
        src="../images/magnifier-glass.svg"
        alt="Nenhum resultado encontrado"
      />
      <h1 style={style.h1}>Nenhum resultado encontrado</h1>
      <p>Os filtros aplicados não encontraram nenhum participante.</p>
    </Flex>
  </Center>
);

const style = {
  h1: {
    color: '#8112ae',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '600',
    margin: '1rem 0',
  },
};

export default EmptyTable;
