import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

import { ChevronRightIcon } from './Icon';

const pathBreadcrumb = [
  {
    path: '/dashboard/home',
    breadcrumb: [],
  },
  {
    path: '/dashboard/acompanhamento',
    breadcrumb: [
      {
        name: 'Acompanhamento de Campanha',
        path: '/dashboard/acompanhamento',
      },
    ],
  },
  {
    path: '/dashboard/etapa',
    breadcrumb: [
      {
        name: 'Etapas da campanha',
        path: '/dashboard/etapa',
      },
    ],
  },
  {
    path: '/dashboard/metricas',
    breadcrumb: [
      {
        name: 'Métricas da Campanha',
        path: '/dashboard/metricas',
      },
    ],
  },
  {
    path: '/dashboard/importacao',
    breadcrumb: [
      {
        name: 'Importação da Campanha',
        path: '/dashboard/importacao',
      },
    ],
  },
];

const Breacrumb = () => {
  const { pathname } = useLocation();
  const paths = pathBreadcrumb.find(item => pathname.includes(item.path));

  return paths?.breadcrumb ? (
    <ChakraBreadcrumb
      separator={<Box as={ChevronRightIcon} stroke="grey.600" />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to="/dashboard/home"
          color="grey.300"
          fontWeight="bold"
        >
          Campanha de Incentivo
        </BreadcrumbLink>
      </BreadcrumbItem>
      {paths.breadcrumb.map(path => (
        <BreadcrumbItem key={path.name}>
          <BreadcrumbLink
            as={Link}
            to={path.path}
            color="grey.300"
            fontWeight="bold"
          >
            {path.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  ) : null;
};

export default Breacrumb;
