const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      width: '100%',
      outline: 0,
      position: 'relative',
      appearance: 'none',
      transition: 'all 0.2s',
    },
  },
  sizes: {
    xl: {
      field: {
        fontSize: 'lg',
        pl: 4,
        pr: 4,
        h: 14,
        borderRadius: 'md',
      },
      addon: {
        fontSize: 'lg',
        pl: 4,
        pr: 4,
        h: 14,
        borderRadius: 'md',
      },
    },
    lg: {
      field: {
        fontSize: 'lg',
        pl: 4,
        pr: 4,
        h: 12,
        borderRadius: 'md',
      },
      addon: {
        fontSize: 'lg',
        pl: 4,
        pr: 4,
        h: 12,
        borderRadius: 'md',
      },
    },
    md: {
      field: {
        fontSize: 'md',
        pl: 4,
        pr: 4,
        h: '38px',
        borderRadius: 'md',
        _focus: { borderColor: 'brand-main', borderWidth: '2px' },
      },
      addon: {
        fontSize: 'md',
        pl: 4,
        pr: 4,
        h: '38px',
        borderRadius: 'md',
        _focus: { borderColor: 'brand-main', borderWidth: '2px' },
      },
    },
    sm: {
      field: {
        fontSize: 'sm',
        pl: 3,
        pr: 3,
        h: 8,
        borderRadius: 'sm',
      },
      addon: {
        fontSize: 'sm',
        pl: 3,
        pr: 3,
        h: 8,
        borderRadius: 'sm',
      },
    },
  },
  variants: {
    unstyled: {
      field: {
        bg: 'transparent',
        pl: 0,
        pr: 0,
        height: 'auto',
      },
      addon: {
        bg: 'transparent',
        pl: 0,
        pr: 0,
        height: 'auto',
      },
    },
    outline: {
      field: {
        borderColor: '#ddddee',
        outline: 0,
        _hover: null,
      },
      addon: {
        borderColor: '#ddddee',
        outline: 0,
        _hover: null,
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export default Input;
