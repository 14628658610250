import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import theme from './styles/theme';
import Routes from './routes';

import { AuthProviver } from './hooks/useAuth';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthProviver>
          <Routes />
        </AuthProviver>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
