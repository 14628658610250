import { useState } from 'react';
import {
  Input,
  Flex,
  InputRightElement,
  InputGroup,
  Text,
  Center,
} from '@chakra-ui/react';

import VariableBadge from './VariableBadge';
import { CheckSoloIcon } from '../shared/Icon';
import { useKpi } from '../../hooks/useKpi';

const Sidebar = () => {
  const [value, setValue] = useState('');
  const { variableList, setVariableList, handleRemoveVariable } = useKpi();

  const onChange = newValue => {
    const newValueSanitized = newValue
      .replace(/[^A-Z0-9_]/gi, '')
      .toLowerCase();
    setValue(newValueSanitized);
  };

  const onSubmitVariable = variableSubmit => {
    const isDuplicated = variableList.some(v => v === variableSubmit);

    if (isDuplicated || !variableSubmit) {
      return;
    }

    setVariableList(oldVariableList => [...oldVariableList, variableSubmit]);
    setValue('');
  };

  const onSubmit = event => {
    event.preventDefault();
    onSubmitVariable(value);
  };

  return (
    <Flex flexDir="column" borderRight="1px solid #F0F0F9" width="450px">
      <Flex
        borderBottom="1px solid #F0F0F9"
        px="30px"
        height="60px"
        width="100%"
        alignItems="center"
      >
        <Text fontSize="xl" color="grey.300">
          Variaveis Personalizadas
        </Text>
      </Flex>
      <Flex p={8} flexDir="column">
        {!!variableList.length && (
          <Flex mb={2} maxWidth="100%" flexWrap="wrap">
            {variableList.map((variable, index) => (
              <VariableBadge
                key={index}
                variable={variable}
                onRemove={() => handleRemoveVariable(index)}
                mb={2}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

Sidebar.defaultProps = {
  variableList: [],
};

export default Sidebar;
