/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import Downshift from 'downshift';

const InputAutoComplete = ({
  value,
  onKeydown,
  inputItems,
  onChange,
  onConfirm,
  ...rest
}) => {
  const [values, setValues] = useState([...inputItems]);

  return (
    <Downshift
      itemToString={item => item || ''}
      inputValue={value}
      onInputValueChange={newValue => {
        if (Number(newValue) && !values.includes(newValue)) {
          values.push(newValue);
        }

        return onChange(newValue);
      }}
      onChange={selection => onConfirm(selection)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
      }) => {
        console.log(getRootProps(), values);
        return (
          <Flex flex="1" position="relative" {...rest}>
            <Box
              display="inline-block"
              marginLeft="5px"
              w="100%"
              {...getRootProps({}, { suppressRefError: true })}
            >
              <Box
                as="input"
                id="teste"
                name="teste"
                w="100%"
                {...getInputProps()}
                onKeyDown={onKeydown}
              />
            </Box>
            {isOpen && (
              <Box
                {...getMenuProps()}
                maxHeight={24}
                minWidth="200px"
                overflowY="auto"
                backgroundColor="#eee"
                padding="0"
                top={8}
                listStyleType="none"
                position="absolute"
                borderBottomRadius="md"
                borderLeft="1px solid #F0F0F9"
                borderRight="1px solid #F0F0F9"
                borderBottom="1px solid #F0F0F9"
                as="ul"
                zIndex="9999"
              >
                {values &&
                  values
                    .filter(item => !inputValue || item.includes(inputValue))
                    .map((item, index) => {
                      return (
                        <Box
                          as="li"
                          px={2}
                          py={1}
                          cursor="pointer"
                          backgroundColor={
                            highlightedIndex === index ? 'lightgray' : 'white'
                          }
                          {...getItemProps({
                            key: item,
                            index,
                            item,
                          })}
                        >
                          {item}
                        </Box>
                      );
                    })}
              </Box>
            )}
          </Flex>
        );
      }}
    </Downshift>
  );
};

InputAutoComplete.propTypes = {
  value: PropTypes.string.isRequired,
  inputItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onKeydown: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default InputAutoComplete;
