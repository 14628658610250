import { useState, useEffect } from 'react';
import { Flex, Text, SimpleGrid, useDisclosure } from '@chakra-ui/react';

import Card from '../components/CampaignSteps/Card';
import { getSteps } from '../services/api';
import EmptyCard from '../components/CampaignSteps/EmptyCard';
import ModalStep from '../components/CampaignSteps/ModalStep';

const CampaignSteps = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stepList, setStepList] = useState([]);

  const handleUpdateSteps = step => {
    setStepList(prevState => [...prevState, step]);
    onClose();
  };

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        const data = await getSteps();
        setStepList(data);
      } catch (e) {
        // eslint-disable-next-line
        console.warn('Erro ao carregar etapas');
      }
    };

    fetchSteps();
  }, []);

  return (
    <Flex
      border="1px solid #F0F0F9"
      borderRadius="lg"
      margin={4}
      backgroundColor="white"
      width="100%"
      minHeight="100vh"
      height="100%"
      flexDir="column"
      paddingX={8}
      paddingY={10}
    >
      <Text mb={14} as="h2" fontWeight="bold" fontSize="2xl">
        Etapas da campanha
      </Text>
      <SimpleGrid
        as={Flex}
        columns={{
          base: 2,
          lg: 3,
          xl: 5,
        }}
        spacing={8}
      >
        <EmptyCard onClick={onOpen} />
        {stepList.map(stepItem => (
          <Card
            key={stepItem.id}
            id={stepItem.id}
            startDate={stepItem.start_date}
            endDate={stepItem.end_date}
            name={stepItem.name}
          />
        ))}
        <ModalStep
          isOpen={isOpen}
          onSuccess={handleUpdateSteps}
          onClose={onClose}
        />
      </SimpleGrid>
    </Flex>
  );
};

export default CampaignSteps;
