/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgDelete(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 4h10l-.833 12H5.833L5 4z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        d="M16 4H4M10 5v11"
      />
    </svg>
  );
}

export default SvgDelete;
