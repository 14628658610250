import { extendTheme } from '@chakra-ui/react';

import Button from './button';
import Input from './input';
import colors from './colors';

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "75em",
}


const theme = extendTheme({
  colors,
  breakpoints,
  styles: {
    global: {
      '*': {
        margin: 0,
        padding: 0,
        outline: 0,
        boxSizing: 'border-box',
      },
      body: {
        backgroundColor: 'grey',
        WebkitFontSmoothing: 'antialiased',
        fontFamily: 'body',
      },
      '.app': {
        display: 'grid',
        gridTemplateColumns: '6rem 1fr',
        gridTemplateRows: '6rem 1fr',
      },
      '#root': {
        minHeight: '100vh',
        height: '100%',
      },
      '::-moz-selection': {
        color: 'red.200',
        background: 'yellow.200',
      },
      '::selection': {
        color: 'white',
        background: 'brand-brighter',
      },
    },
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    heading: "'Sarabun', sans-serif",
    body: "'Sarabun', sans-serif",
    mono: "'Sarabun', sans-serif",
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '42px',
    '6xl': '64px',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  sizes: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
    px: '1px',
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
  },
  shadows: {
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    none: 'none',
    'dark-lg':
      'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
  },
  space: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
    px: '1px',
  },
  borders: {
    none: 0,
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',
    '8px': '8px solid',
  },
  transition: {
    property: {
      common:
        'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      dimensions: 'width, height',
      position: 'left, right, top, bottom',
      background: 'background-color, background-image, background-position',
    },
    easing: {
      'ease-in': 'cubic-bezier(0.4, 0, 1, 1)',
      'ease-out': 'cubic-bezier(0, 0, 0.2, 1)',
      'ease-in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    duration: {
      'ultra-fast': '50ms',
      faster: '100ms',
      fast: '150ms',
      normal: '200ms',
      slow: '300ms',
      slower: '400ms',
      'ultra-slow': '500ms',
    },
  },
  components: {
    Accordion: {
      parts: ['container', 'button', 'panel'],
      baseStyle: {
        container: {
          borderTopWidth: '1px',
          borderColor: 'inherit',
          _last: {
            borderBottomWidth: '1px',
          },
        },
        button: {
          fontSize: '1rem',
          _focus: {
            boxShadow: 'outline',
          },
          _hover: {
            bg: 'blackAlpha.50',
          },
          _disabled: {
            opacity: 0.4,
            cursor: 'not-allowed',
          },
          px: 4,
          py: 2,
        },
        panel: {
          pt: 2,
          px: 4,
          pb: 5,
        },
      },
    },
    Alert: {
      parts: ['container', 'title', 'icon'],
      baseStyle: {
        container: {
          px: 4,
          py: 3,
        },
        title: {
          fontSize: 'lg',
          fontWeight: 'bold',
          lineHeight: 6,
          mr: 2,
        },
        description: {
          fontSize: 'md',
          lineHeight: 6,
        },
        icon: {
          mr: 3,
          w: 5,
          h: 6,
        },
      },
      variants: {},
      defaultProps: {
        variant: 'subtle',
      },
    },
    Avatar: {
      parts: ['container', 'excessLabel', 'badge', 'label'],
      sizes: {
        '2xs': {
          container: {
            width: '4',
            height: '4',
            fontSize: 'calc(1rem / 2.5)',
          },
          excessLabel: {
            width: '4',
            height: '4',
          },
          label: {
            fontSize: 'calc(1rem / 2.5)',
            lineHeight: '1rem',
          },
        },
        xs: {
          container: {
            width: '6',
            height: '6',
            fontSize: 'calc(1.5rem / 2.5)',
          },
          excessLabel: {
            width: '6',
            height: '6',
          },
          label: {
            fontSize: 'calc(1.5rem / 2.5)',
            lineHeight: '1.5rem',
          },
        },
        sm: {
          container: {
            width: '8',
            height: '8',
            fontSize: 'calc(2rem / 2.5)',
          },
          excessLabel: {
            width: '8',
            height: '8',
          },
          label: {
            fontSize: 'calc(2rem / 2.5)',
            lineHeight: '2rem',
          },
        },
        md: {
          container: {
            width: '12',
            height: '12',
            fontSize: 'calc(3rem / 2.5)',
          },
          excessLabel: {
            width: '12',
            height: '12',
          },
          label: {
            fontSize: 'calc(3rem / 2.5)',
            lineHeight: '3rem',
          },
        },
        lg: {
          container: {
            width: '16',
            height: '16',
            fontSize: 'calc(4rem / 2.5)',
          },
          excessLabel: {
            width: '16',
            height: '16',
          },
          label: {
            fontSize: 'calc(4rem / 2.5)',
            lineHeight: '4rem',
          },
        },
        xl: {
          container: {
            width: '24',
            height: '24',
            fontSize: 'calc(6rem / 2.5)',
          },
          excessLabel: {
            width: '24',
            height: '24',
          },
          label: {
            fontSize: 'calc(6rem / 2.5)',
            lineHeight: '6rem',
          },
        },
        '2xl': {
          container: {
            width: '32',
            height: '32',
            fontSize: 'calc(8rem / 2.5)',
          },
          excessLabel: {
            width: '32',
            height: '32',
          },
          label: {
            fontSize: 'calc(8rem / 2.5)',
            lineHeight: '8rem',
          },
        },
        full: {
          container: {
            width: '100%',
            height: '100%',
            fontSize: 'calc(100% / 2.5)',
          },
          excessLabel: {
            width: '100%',
            height: '100%',
          },
          label: {
            fontSize: 'calc(100% / 2.5)',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    Badge: {
      baseStyle: {
        px: 1,
        textTransform: 'uppercase',
        fontSize: 'xs',
        borderRadius: 'sm',
        fontWeight: 'bold',
      },
      variants: {},
      defaultProps: {
        variant: 'subtle',
        colorScheme: 'gray',
      },
    },
    Breadcrumb: {
      parts: ['link', 'separator'],
      baseStyle: {
        link: {
          transition: 'all 0.15s ease-out',
          cursor: 'pointer',
          textDecoration: 'none',
          outline: 'none',
          color: 'inherit',
          _hover: {
            textDecoration: 'underline',
          },
          _focus: {
            boxShadow: 'outline',
          },
        },
      },
    },
    Button,
    Checkbox: {
      parts: ['control', 'label', 'description', 'icon'],
      sizes: {
        sm: {
          control: {
            h: 3,
            w: 3,
          },
          label: {
            fontSize: 'sm',
          },
          icon: {
            fontSize: '0.45rem',
          },
        },
        md: {
          control: {
            w: 4,
            h: 4,
          },
          label: {
            fontSize: 'md',
          },
          icon: {
            fontSize: '0.625rem',
          },
        },
        lg: {
          control: {
            w: 5,
            h: 5,
          },
          label: {
            fontSize: 'lg',
          },
          icon: {
            fontSize: '0.625rem',
          },
        },
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    CloseButton: {
      sizes: {
        lg: {
          w: '40px',
          h: '40px',
          fontSize: '16px',
        },
        md: {
          w: '32px',
          h: '32px',
          fontSize: '12px',
        },
        sm: {
          w: '24px',
          h: '24px',
          fontSize: '10px',
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    Code: {
      baseStyle: {
        fontFamily: 'mono',
        fontSize: 'sm',
        px: '0.2em',
        borderRadius: 'sm',
      },
      variants: {},
      defaultProps: {
        variant: 'subtle',
        colorScheme: 'gray',
      },
    },
    Drawer: {
      parts: [
        'overlay',
        'dialogContainer',
        'dialog',
        'header',
        'body',
        'footer',
      ],
      sizes: {
        xs: {
          dialog: {
            maxW: 'xs',
          },
        },
        sm: {
          dialog: {
            maxW: 'md',
          },
        },
        md: {
          dialog: {
            maxW: 'lg',
          },
        },
        lg: {
          dialog: {
            maxW: '2xl',
          },
        },
        xl: {
          dialog: {
            maxW: '4xl',
          },
        },
        full: {
          dialog: {
            maxW: '100vw',
            h: '100vh',
          },
        },
      },
      defaultProps: {
        size: 'xs',
      },
    },
    Editable: {
      parts: ['preview', 'input'],
      baseStyle: {
        preview: {
          borderRadius: 'md',
          py: '3px',
          transition: 'all 0.2s',
        },
        input: {
          borderRadius: 'md',
          py: '3px',
          transition: 'all 0.2s',
          width: 'full',
          _focus: {
            boxShadow: 'outline',
          },
          _placeholder: {
            opacity: 0.6,
          },
        },
      },
    },
    Form: {
      parts: ['requiredIndicator', 'helperText'],
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'md',
        mr: 3,
        mb: 2,
        fontWeight: 'medium',
        transition: 'all 0.2s',
        opacity: 1,
        _disabled: {
          opacity: 0.4,
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'heading',
        fontWeight: 'bold',
      },
      sizes: {
        '4xl': {
          fontSize: ['6xl', null, '7xl'],
          lineHeight: 1,
        },
        '3xl': {
          fontSize: ['5xl', null, '6xl'],
          lineHeight: 1,
        },
        '2xl': {
          fontSize: ['4xl', null, '5xl'],
          lineHeight: [1.2, null, 1],
        },
        xl: {
          fontSize: ['3xl', null, '4xl'],
          lineHeight: [1.33, null, 1.2],
        },
        lg: {
          fontSize: ['2xl', null, '3xl'],
          lineHeight: [1.33, null, 1.2],
        },
        md: {
          fontSize: 'xl',
          lineHeight: 1.2,
        },
        sm: {
          fontSize: 'md',
          lineHeight: 1.2,
        },
        xs: {
          fontSize: 'sm',
          lineHeight: 1.2,
        },
      },
      defaultProps: {
        size: 'xl',
      },
    },
    Input,
    Kbd: {},
    Link: {
      baseStyle: {
        transition: 'all 0.15s ease-out',
        cursor: 'pointer',
        textDecoration: 'none',
        outline: 'none',
        color: 'inherit',
        _hover: {
          textDecoration: 'underline',
        },
        _focus: {
          boxShadow: 'outline',
        },
      },
    },
    Menu: {
      parts: ['item', 'command', 'list', 'button', 'groupTitle', 'divider'],
    },
    Modal: {
      parts: [
        'overlay',
        'dialogContainer',
        'dialog',
        'header',
        'body',
        'footer',
      ],
      sizes: {
        xs: {
          dialog: {
            maxW: 'xs',
          },
        },
        sm: {
          dialog: {
            maxW: 'sm',
          },
        },
        md: {
          dialog: {
            maxW: 'md',
          },
        },
        lg: {
          dialog: {
            maxW: 'lg',
          },
        },
        xl: {
          dialog: {
            maxW: 'xl',
          },
        },
        '2xl': {
          dialog: {
            maxW: '2xl',
          },
        },
        '3xl': {
          dialog: {
            maxW: '3xl',
          },
        },
        '4xl': {
          dialog: {
            maxW: '4xl',
          },
        },
        '5xl': {
          dialog: {
            maxW: '5xl',
          },
        },
        '6xl': {
          dialog: {
            maxW: '6xl',
          },
        },
        full: {
          dialog: {
            maxW: '100vw',
            h: '100vh',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    NumberInput: {
      parts: ['field', 'stepper', 'stepperGroup'],
      sizes: {
        sm: {
          field: {
            fontSize: 'sm',
            pl: 3,
            pr: 3,
            h: 8,
            borderRadius: 'sm',
          },
          stepper: {
            fontSize: '10px',
            _first: {
              borderTopRightRadius: 'sm',
            },
            _last: {
              borderBottomRightRadius: 'sm',
              mt: '-1px',
              borderTopWidth: 1,
            },
          },
        },
        md: {
          field: {
            fontSize: 'md',
            pl: 4,
            pr: 4,
            h: 10,
            borderRadius: 'md',
          },
          stepper: {
            fontSize: '10px',
            _first: {
              borderTopRightRadius: 'md',
            },
            _last: {
              borderBottomRightRadius: 'md',
              mt: '-1px',
              borderTopWidth: 1,
            },
          },
        },
        lg: {
          field: {
            fontSize: 'lg',
            pl: 4,
            pr: 4,
            h: 12,
            borderRadius: 'md',
          },
          stepper: {
            fontSize: '14px',
            _first: {
              borderTopRightRadius: 'md',
            },
            _last: {
              borderBottomRightRadius: 'md',
              mt: '-1px',
              borderTopWidth: 1,
            },
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            bg: 'transparent',
            pl: 0,
            pr: 0,
            height: 'auto',
          },
          addon: {
            bg: 'transparent',
            pl: 0,
            pr: 0,
            height: 'auto',
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    PinInput: {
      baseStyle: {
        width: '100%',
        outline: 0,
        position: 'relative',
        appearance: 'none',
        transition: 'all 0.2s',
        textAlign: 'center',
      },
      sizes: {
        lg: {
          fontSize: 'lg',
          w: 12,
          h: 12,
          borderRadius: 'md',
        },
        md: {
          fontSize: 'md',
          w: 10,
          h: 10,
          borderRadius: 'md',
        },
        sm: {
          fontSize: 'sm',
          w: 8,
          h: 8,
          borderRadius: 'sm',
        },
      },
      variants: {
        unstyled: {
          bg: 'transparent',
          pl: 0,
          pr: 0,
          height: 'auto',
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    Popover: {
      parts: ['popper', 'content', 'header', 'body', 'footer', 'arrow'],
    },
    Progress: {
      parts: ['track', 'filledTrack', 'panel'],
      sizes: {
        xs: {
          track: {
            h: '0.25rem',
          },
        },
        sm: {
          track: {
            h: '0.5rem',
          },
        },
        md: {
          track: {
            h: '0.75rem',
          },
        },
        lg: {
          track: {
            h: '1rem',
          },
        },
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    Radio: {
      parts: ['control', 'label'],
      sizes: {
        md: {
          control: {
            w: 4,
            h: 4,
          },
          label: {
            fontSize: 'md',
          },
        },
        lg: {
          control: {
            w: 5,
            h: 5,
          },
          label: {
            fontSize: 'lg',
          },
        },
        sm: {
          control: {
            width: 3,
            height: 3,
          },
          label: {
            fontSize: 'sm',
          },
        },
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    Select: {
      parts: ['field', 'icon'],
      sizes: {
        lg: {
          field: {
            fontSize: 'lg',
            pl: 4,
            pr: 4,
            h: 12,
            borderRadius: 'md',
          },
          addon: {
            fontSize: 'lg',
            pl: 4,
            pr: 4,
            h: 12,
            borderRadius: 'md',
          },
        },
        md: {
          field: {
            fontSize: 'md',
            pl: 4,
            pr: 4,
            h: 10,
            borderRadius: 'md',
          },
          addon: {
            fontSize: 'md',
            pl: 4,
            pr: 4,
            h: 10,
            borderRadius: 'md',
          },
        },
        sm: {
          field: {
            fontSize: 'sm',
            pl: 3,
            pr: 3,
            h: 8,
            borderRadius: 'sm',
          },
          addon: {
            fontSize: 'sm',
            pl: 3,
            pr: 3,
            h: 8,
            borderRadius: 'sm',
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            bg: 'transparent',
            pl: 0,
            pr: 0,
            height: 'auto',
          },
          addon: {
            bg: 'transparent',
            pl: 0,
            pr: 0,
            height: 'auto',
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    Skeleton: {},
    SkipLink: {},
    Slider: {
      parts: ['container', 'thumb', 'track', 'filledTrack'],
      sizes: {},
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    Spinner: {
      sizes: {
        xs: {
          w: '0.75rem',
          h: '0.75rem',
        },
        sm: {
          w: '1rem',
          h: '1rem',
        },
        md: {
          w: '1.5rem',
          h: '1.5rem',
        },
        lg: {
          w: '2rem',
          h: '2rem',
        },
        xl: {
          w: '3rem',
          h: '3rem',
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    Stat: {
      parts: ['label', 'number', 'icon', 'helpText'],
      baseStyle: {
        label: {
          fontWeight: 'medium',
        },
        helpText: {
          opacity: 0.8,
          marginBottom: 2,
        },
        number: {
          verticalAlign: 'baseline',
          fontWeight: 'semibold',
        },
        icon: {
          mr: 1,
          w: '14px',
          h: '14px',
          verticalAlign: 'middle',
        },
      },
      sizes: {
        md: {
          label: {
            fontSize: 'sm',
          },
          helpText: {
            fontSize: 'sm',
          },
          number: {
            fontSize: '2xl',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    Switch: {
      parts: ['track', 'thumb'],
      sizes: {
        sm: {
          track: {
            w: '1.375rem',
            h: '0.75rem',
          },
          thumb: {
            w: '0.75rem',
            h: '0.75rem',
            _checked: {
              transform: 'translateX(0.625rem)',
            },
          },
        },
        md: {
          track: {
            w: '1.875rem',
            h: '1rem',
          },
          thumb: {
            w: '1rem',
            h: '1rem',
            _checked: {
              transform: 'translateX(0.875rem)',
            },
          },
        },
        lg: {
          track: {
            w: '2.875rem',
            h: '1.5rem',
          },
          thumb: {
            w: '1.5rem',
            h: '1.5rem',
            _checked: {
              transform: 'translateX(1.375rem)',
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    Tabs: {
      parts: ['root', 'tablist', 'tab', 'tabpanel', 'indicator'],
      sizes: {
        sm: {
          tab: {
            py: '0.25rem',
            px: '1rem',
            fontSize: '0.85rem',
          },
        },
        md: {
          tab: {
            fontSize: '1rem',
            py: '0.5rem',
            px: '1rem',
          },
        },
        lg: {
          tab: {
            fontSize: '1.15rem',
            py: '0.75rem',
            px: '1rem',
          },
        },
      },
      variants: {
        unstyled: {},
      },
      defaultProps: {
        size: 'md',
        variant: 'line',
        colorScheme: 'blue',
      },
    },
    Tag: {
      parts: ['container', 'label', 'closeButton'],
      variants: {},
      baseStyle: {
        container: {
          fontWeight: 'medium',
          lineHeight: 1.2,
          outline: 0,
          _focus: {
            boxShadow: 'outline',
          },
        },
        label: {
          lineHeight: 1.2,
        },
        closeButton: {
          fontSize: '18px',
          w: '1.25rem',
          h: '1.25rem',
          borderRadius: 'full',
          ml: '0.375rem',
          mr: '-1',
          opacity: 0.5,
          _disabled: {
            opacity: 0.4,
          },
          _focus: {
            boxShadow: 'outline',
            bg: 'rgba(0, 0, 0, 0.14)',
          },
          _hover: {
            opacity: 0.8,
          },
          _active: {
            opacity: 1,
          },
        },
      },
      sizes: {
        sm: {
          container: {
            minH: '1.25rem',
            minW: '1.25rem',
            fontSize: 'xs',
            px: 2,
            borderRadius: 'md',
          },
          closeButton: {
            mr: '-2px',
            ml: '0.35rem',
          },
        },
        md: {
          container: {
            minH: '1.5rem',
            minW: '1.5rem',
            fontSize: 'sm',
            borderRadius: 'md',
            px: 2,
          },
        },
        lg: {
          container: {
            minH: 8,
            minW: 8,
            fontSize: 'md',
            borderRadius: 'md',
            px: 3,
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'subtle',
        colorScheme: 'gray',
      },
    },
    Textarea: {
      baseStyle: {
        width: '100%',
        outline: 0,
        position: 'relative',
        appearance: 'none',
        transition: 'all 0.2s',
        paddingY: '8px',
        minHeight: '80px',
        lineHeight: 'short',
      },
      sizes: {
        sm: {
          fontSize: 'sm',
          pl: 3,
          pr: 3,
          h: 8,
          borderRadius: 'sm',
        },
        md: {
          fontSize: 'md',
          pl: 4,
          pr: 4,
          h: 10,
          borderRadius: 'md',
        },
        lg: {
          fontSize: 'lg',
          pl: 4,
          pr: 4,
          h: 12,
          borderRadius: 'md',
        },
      },
      variants: {
        unstyled: {
          bg: 'transparent',
          pl: 0,
          pr: 0,
          height: 'auto',
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    Tooltip: {},
    FormError: {
      parts: ['text', 'icon'],
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  textStyles: {
    h1: {
      fontSize: '9.6rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '6rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: ['3.4rem', '4.8rem'],
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '3.4rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
  },
});

export default theme;
