export const baseUrl = process.env.REACT_APP_PAT_API_URL;
// export const baseUrl = 'http://localhost:3333/api';

export const login = `${baseUrl}/admin/login`;
export const publicParticipant = `${baseUrl}/public/participant`;
export const publicUser = `${baseUrl}/public/participant/user`;

export const steps = `${baseUrl}/admin/campaign/step/?campaign=default`;

export const importCampaign = `${baseUrl}/admin/campaign/import`;
export const getImportList = `${baseUrl}/admin/campaign/import`;
export const getParticipantList = `${baseUrl}/`;
export const getSheetExample = `${baseUrl}/admin/campaign/config`;

export const stepEndpoint = `${baseUrl}/admin/campaign/step`;
export const kpi = `${baseUrl}/admin/kpi`;
export const kpiPrize = `${baseUrl}/admin/results/prize`;

export const patEndpoints = {
  participantsList: '/admin/participant',
};
