import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Text,
  Modal,
  Center,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

import InputAutoComplete from './InputAutoComplete';
import VariableBadge from './VariableBadge';

const ModalRankingPrizes = ({
  isOpen,
  onClose,
  onSuccess,
  initialRankingList,
  initialTiebreakerList,
  autoCompleteValues,
}) => {
  const [rankingList, setRankingList] = useState(initialRankingList);
  const [tiebreakerList, setTiebreakerList] = useState(initialTiebreakerList);

  useEffect(() => {
    setRankingList(initialRankingList);
  }, [initialRankingList]);

  useEffect(() => {
    setTiebreakerList(initialTiebreakerList);
  }, [initialTiebreakerList]);

  const onChangeTiebreaker = (value, position) => {
    setTiebreakerList(
      tiebreakerList.map((tiebreakerItem, index) => {
        if (position === index) {
          return {
            ...tiebreakerItem,
            currentValue: value,
          };
        }
        return tiebreakerItem;
      }),
    );
  };

  const onConfirmTiebreaker = (value, position) => {
    const isValid = autoCompleteValues.some(item => item === value);

    if (isValid) {
      setTiebreakerList(oldTiebreakerList =>
        oldTiebreakerList.map((tiebreaker, index) => {
          if (position !== index) {
            return tiebreaker;
          }
          const hasValue = tiebreaker.tiebreaker.length > 0;
          if (hasValue) {
            return tiebreaker;
          }
          return {
            currentValue: '',
            tiebreaker: [value],
          };
        }),
      );
    }
  };

  const onKeyDownTiebreaker = (code, currentValue, position) => {
    if (code === 'Enter' || code === 'Space') {
      onConfirmTiebreaker(currentValue, position);
    }
    if (code === 'Backspace' && currentValue === '') {
      setTiebreakerList(
        tiebreakerList.map((t, index) =>
          index === position
            ? {
                ...t,
                tiebreaker: t.tiebreaker.splice(0, t.tiebreaker.length - 1),
              }
            : t,
        ),
      );
    }
  };

  const onConfirmPosition = (value, position) => {
    console.log('Ranking List', rankingList, position);

    setRankingList(
      rankingList.map((t, index) => {
        const isValid = t.position.length === 0;

        if (index === position) {
          const values = isValid ? [value] : [...t.position, value];

          return {
            ...t,
            position: values,
            positionCurrentValue: '',
          };
        }
        return t;
      }),
    );
  };

  const onKeyDownPosition = (code, currentValue, position) => {
    if (code === 'Enter' || code === 'Space') {
      onConfirmPosition(currentValue, position);
    }
    if (code === 'Backspace' && currentValue === '') {
      setRankingList(
        rankingList.map((t, index) =>
          index === position
            ? {
                ...t,
                position: t.position.splice(0, t.position.length - 1),
              }
            : t,
        ),
      );
    }
  };

  const onPositionChange = (value, position) =>
    setRankingList(
      rankingList.map((rankingItem, index) => {
        if (position === index) {
          return {
            ...rankingItem,
            positionCurrentValue: value.replace(/[^0-9.]/g, ''),
          };
        }
        return rankingItem;
      }),
    );

  const onConfirmPrize = (value, position) => {
    setRankingList(
      rankingList.map((t, index) => {
        const isValid = t.prize.length === 0;
        if (index === position && isValid) {
          return {
            ...t,
            prize: [value],
            prizeCurrentValue: '',
          };
        }
        return t;
      }),
    );
  };

  const onKeyDownPrize = (code, currentValue, position) => {
    console.log(code, currentValue, position);
    if (code === 'Enter' || code === 'Space') {
      onConfirmPrize(currentValue, position);
    }
    if (code === 'Backspace' && currentValue === '') {
      setRankingList(
        rankingList.map((t, index) =>
          index === position
            ? {
                ...t,
                prize: t.prize.splice(0, t.prize.length - 1),
              }
            : t,
        ),
      );
    }
  };

  const onPrizeChange = (value, position) =>
    setRankingList(
      rankingList.map((rankingItem, index) => {
        if (position === index) {
          return {
            ...rankingItem,
            prizeCurrentValue: value.replace(/[^0-9.]/g, ''),
          };
        }
        return rankingItem;
      }),
    );

  const handleAddTiebreaker = () => {
    setTiebreakerList([
      ...tiebreakerList,
      {
        tiebreaker: [],
        currentValue: '',
      },
    ]);
  };

  const handleAddRanking = () => {
    setRankingList([
      ...rankingList,
      {
        prizeType: '',
        prize: [],
        prizeCurrentValue: '',
        positionType: '',
        position: [],
        positionCurrentValue: '',
      },
    ]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent width="90vw" h="fit-content">
        <ModalHeader borderBottom="1px solid #F0F0F9">
          <Text>Regras de ranqueamento</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody w="100%" p="0" as={Flex} flexDir="row" h="70vh">
          <Flex
            width="100%"
            maxW="50%"
            maxH="100%"
            flexDir="column"
            borderRight="1px solid #F0F0F9"
            overflowY="scroll"
            css={{
              '&::-webkit-scrollbar': {
                width: '0',
              },
            }}
          >
            <Flex
              py={4}
              pl={8}
              w="100%"
              borderBottom="1px solid #F0F0F9"
              alignItems="center"
            >
              <Text color="grey.300" fontWeight="bold" fontSize="xl">
                Critérios de Desempate
              </Text>
            </Flex>
            <Flex w="100%" h="100%" p={8} flexDir="column">
              {tiebreakerList.map(({ tiebreaker, currentValue }, index) => (
                <Flex
                  key={index}
                  w="100%"
                  border="1px solid #DDDDEE"
                  h={12}
                  borderRadius="md"
                  alignItems="center"
                  mb={4}
                >
                  <Center
                    bg="grey.700"
                    color="grey.200"
                    w={12}
                    h="100%"
                    borderTopLeftRadius="md"
                    borderBottomLeftRadius="md"
                    borderRight="1px solid #DDDDEE"
                  >
                    {index + 1}
                  </Center>
                  <Flex h="100%" w="100%" pl={4} alignItems="center">
                    <>
                      {tiebreaker.map((tiebreakerItem, i) => (
                        <VariableBadge
                          key={i}
                          variable={tiebreakerItem}
                          color="#2AC644"
                          bg="#EBFFEE"
                        />
                      ))}
                      <InputAutoComplete
                        inputItems={autoCompleteValues}
                        onChange={newValue =>
                          onChangeTiebreaker(newValue, index)
                        }
                        value={currentValue}
                        onConfirm={() =>
                          onConfirmTiebreaker(currentValue, index)
                        }
                        onKeydown={event =>
                          onKeyDownTiebreaker(event.code, currentValue, index)
                        }
                      />
                    </>
                  </Flex>
                </Flex>
              ))}
              <Flex
                border="1px dashed #DDDDEE"
                minH={12}
                pl={4}
                w="100%"
                alignItems="center"
                borderRadius="md"
                onClick={handleAddTiebreaker}
                cursor="pointer"
              >
                <Text fontSize="sm" color="grey.200">
                  Adicionar novo critério de desempate...
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width="100%"
            maxW="50%"
            maxH="100%"
            flexDir="column"
            overflowY="scroll"
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Flex
              w="100%"
              py={4}
              pl={8}
              borderBottom="1px solid #F0F0F9"
              alignItems="center"
            >
              <Text color="grey.300" fontWeight="bold" fontSize="xl">
                Critérios para Premiação
              </Text>
            </Flex>
            <Flex w="100%" h="100%" flexDir="column" p={4}>
              {rankingList.map((rankingItem, index) => (
                <Flex
                  p={4}
                  key={index}
                  mb={4}
                  flexDir="column"
                  borderRadius="md"
                  border="1px solid #DDDDEE"
                >
                  <Text color="grey.300" fontSize="md" ml={7} mb={3}>
                    A premiação será de
                  </Text>
                  <Flex
                    h={12}
                    border="1px solid #DDDDEE"
                    borderRadius="md"
                    position="relative"
                    _after={{
                      content: '""',
                      height: '20px',
                      width: '1px',
                      background: '#DDDDEE',
                      position: 'absolute',
                      top: '-20px',
                      left: '18px',
                    }}
                    _before={{
                      content: '""',
                      height: '7px',
                      width: '7px',
                      borderRadius: '50%',
                      border: '2px solid #2AC644',
                      position: 'absolute',
                      top: '-27px',
                      left: '15px',
                    }}
                  >
                    <Center
                      bg="grey.700"
                      color="grey.200"
                      w={20}
                      h="100%"
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                      borderRight="1px solid #DDDDEE"
                    >
                      Pontos
                    </Center>
                    <Flex h="100%" w="100%" pl={4} alignItems="center">
                      <>
                        {rankingItem.prize.map((prizeItem, i) => {
                          return (
                            <VariableBadge
                              key={i}
                              variable={prizeItem}
                              color="#2AC644"
                              bg="#EBFFEE"
                            />
                          );
                        })}
                        <input
                          onChange={event =>
                            onPrizeChange(event.target.value, index)
                          }
                          onKeyDown={event =>
                            onKeyDownPrize(
                              event.code,
                              rankingItem.prizeCurrentValue,
                              index,
                            )
                          }
                          value={rankingItem.prizeCurrentValue}
                        />
                      </>
                    </Flex>
                  </Flex>
                  <Text color="grey.300" fontSize="md" ml={7} mb={3} mt={4}>
                    Concedido à
                  </Text>
                  <Flex
                    h={12}
                    border="1px solid #DDDDEE"
                    borderRadius="md"
                    position="relative"
                    _after={{
                      content: '""',
                      height: '20px',
                      width: '1px',
                      background: '#DDDDEE',
                      position: 'absolute',
                      top: '-20px',
                      left: '18px',
                    }}
                    _before={{
                      content: '""',
                      height: '7px',
                      width: '7px',
                      borderRadius: '50%',
                      border: '2px solid #2AC644',
                      position: 'absolute',
                      top: '-27px',
                      left: '15px',
                    }}
                  >
                    <Center
                      bg="grey.700"
                      color="grey.200"
                      w={20}
                      h="100%"
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                      borderRight="1px solid #DDDDEE"
                    >
                      Posição
                    </Center>
                    <Flex h="100%" w="100%" pl={4} alignItems="center">
                      <>
                        {rankingItem.position.map((positionItem, i) =>
                          Array.isArray(positionItem) ? (
                            positionItem
                              .flat(10)
                              .map((item, ind) => (
                                <VariableBadge
                                  key={`${i}-${ind}`}
                                  variable={item}
                                  color="#2AC644"
                                  bg="#EBFFEE"
                                />
                              ))
                          ) : (
                            <VariableBadge
                              key={`${i}`}
                              variable={positionItem}
                              color="#2AC644"
                              bg="#EBFFEE"
                            />
                          ),
                        )}
                        <input
                          onChange={event =>
                            onPositionChange(event.target.value, index)
                          }
                          onKeyDown={event =>
                            onKeyDownPosition(
                              event.code,
                              rankingItem.positionCurrentValue,
                              index,
                            )
                          }
                          value={rankingItem.positionCurrentValue}
                        />
                      </>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
              <Flex
                border="1px dashed #DDDDEE"
                minH={12}
                pl={4}
                w="100%"
                alignItems="center"
                borderRadius="md"
                onClick={handleAddRanking}
                cursor="pointer"
              >
                <Text fontSize="sm" color="grey.200">
                  Adicionar novo Critério de Premiação...
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          p={0}
          as={Flex}
          height="60px"
          borderTop="1px solid #F0F0F9"
          justifyContent="flex-end"
        >
          <Flex marginY="auto" mr={8} alignItems="center">
            <Text
              textAlign="center"
              cursor="pointer"
              fontWeight="bold"
              fontSize="md"
              onClick={onClose}
            >
              Descartar Alterações
            </Text>
            <Button
              ml={8}
              px={8}
              borderRadius="3xl"
              color="white"
              bg="brand-main"
              onClick={() => onSuccess(tiebreakerList, rankingList)}
              _hover={{
                bg: 'brand-brighter',
              }}
            >
              Salvar Alterações
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalRankingPrizes.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  initialTiebreakerList: PropTypes.arrayOf(
    PropTypes.shape({
      tiebreaker: PropTypes.arrayOf(PropTypes.string),
      currentValue: PropTypes.string,
    }),
  ),
  initialRankingList: PropTypes.arrayOf(
    PropTypes.shape({
      prizeType: PropTypes.string,
      prizeValue: PropTypes.string,
      target: PropTypes.string,
      targetValue: PropTypes.string,
    }),
  ),
  autoCompleteValues: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ModalRankingPrizes.defaultProps = {
  initialTiebreakerList: [
    {
      tiebreaker: [],
      currentValue: '',
    },
  ],
  initialRankingList: [
    {
      prizeType: '',
      prizeValue: '',
      target: '',
      targetValue: '',
    },
  ],
};

export default ModalRankingPrizes;
