import PropTypes from 'prop-types';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button, Table, Thead, Tbody, Tr, Th,  Td,
} from '@chakra-ui/react';

const ModalPrizeKpi = ({ isOpen, onCancel, data, loading }) => (
  <AlertDialog isOpen={isOpen} onClose={onCancel} size="2xl">
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Ranking de Premiação
        </AlertDialogHeader>
        <AlertDialogBody>
          {
            loading ? <p>Carregando informações sobre a premiação</p> : null
          }
          {
            !loading && data.length === 0 ? <p>Não há premiação para este KPI</p> : null
          }
          <Table variant='striped' colorScheme='gray'>
            <Thead>
              <Tr>
                <Th>Posição</Th>
                <Th>Qtde Pontos</Th>
                <Th>Nome</Th>
                <Th>Nível</Th>
                <Th>Documento</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                data && data.length > 0 && data.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      {item.position}
                      º
                    </Td>
                    <Td>{item.prize}</Td>
                    <Td>{item?.participant?.name}</Td>
                    <Td>{item?.participant?.level}</Td>
                    <Td>{item.participant?.document}</Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>




        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onCancel}>Cancelar</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

ModalPrizeKpi.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ModalPrizeKpi;
