import { Switch } from 'react-router-dom';

import DashboardRoutes from './DashboardRoutes';
import Route from './Route';
import Login from '../pages/Login';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/dashboard" component={DashboardRoutes} />
  </Switch>
);

export default Routes;
