import PropTypes from 'prop-types';
import { Center, Box, Text } from '@chakra-ui/react';

import { XIcon } from '../shared/Icon';

const VariableBadge = ({ variable, onRemove, ...rest }) => {
  return (
    <Center
      width="max-content"
      borderRadius="15px"
      h={7}
      bg="grey.700"
      mr={2}
      px="10px"
      color="grey.300"
      key={variable}
      {...rest}
    >
      <Text fontSize="sm" as="span">
        {variable}
      </Text>
      {onRemove && (
        <Box
          as={XIcon}
          stroke="grey.200"
          cursor="pointer"
          mt="px"
          mr="-8px"
          onClick={onRemove}
        />
      )}
    </Center>
  );
};

VariableBadge.propTypes = {
  variable: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

VariableBadge.defaultProps = {
  onRemove: null,
};

export default VariableBadge;
