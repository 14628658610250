import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Header = () => (
  <Box as={Link} to="/" mb="10.5vh">
    <Image
      height="72px"
      width="60px"
      objectFit="cover"
      src="images/pat-logo.svg"
      alt=""
    />
  </Box>
);

export default Header;
