import { Center, Flex, Text, Image } from '@chakra-ui/react';

const EmptyVariableList = () => (
  <Center as={Flex} flexDir="column" mb={10} mt={16} w="100%">
    <Image
      alt=""
      height={48}
      objectFit="cover"
      src="/images/variable-add.svg"
      width={48}
    />
    <Text fontWeight="bold" fontSize="xl" mt={8}>
      Nenhuma métrica adicionada
    </Text>
    <Text fontSize="md" mt={4}>
      Adicione uma métrica ao lado para continuar
    </Text>
  </Center>
);

export default EmptyVariableList;
