import { useState, useEffect } from 'react';
import { Flex, Text, SimpleGrid } from '@chakra-ui/react';

import { getSteps } from '../services/api';
import Card from '../components/CampaignSteps/Card';

const Metrics = () => {
  const [stepList, setStepList] = useState([]);

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        const data = await getSteps();
        setStepList(data);
        console.log(data)
      } catch (e) {
        // eslint-disable-next-line
        console.warn('Erro ao carregar etapas');
      }
    };

    fetchSteps();
  }, []);

  return (
    <Flex
      border="1px solid #F0F0F9"
      borderRadius="lg"
      margin={4}
      backgroundColor="white"
      width="100%"
      minHeight="100vh"
      height="100%"
      flexDir="column"
      paddingX={8}
      paddingY={10}
    >
      <Text mb={14} as="h2" fontWeight="bold" fontSize="2xl">
        Selecione uma das etapas da campanha para atualizar
      </Text>
      <SimpleGrid
        as={Flex}
        columns={{
          base: 2,
          lg: 3,
          xl: 4,
        }}
        spacing={8}
      >
        {stepList.map(stepItem =>{
          console.log(stepItem)
          return (
            <Card
              key={stepItem.id}
              id={stepItem.id}
              startDate={stepItem.start_date}
              endDate={stepItem.end_date}
              name={stepItem.name}
            />
          )
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default Metrics;
