/* eslint-disable no-console */
import { useState } from 'react';
import { Flex, Box, Center, Image, Grid } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { NavLink, Link } from 'react-router-dom';
import uuid from 'react-uuid';

import Subnavbar from './Subnavbar';

import {
  HomeIcon,
  PeopleIcon,
  MechanicsIcon,
  // BarGraphIcon,
  // ImportIcon,
} from './Icon';

const navItems = [
  {
    icon: HomeIcon,
    title: 'Página Inicial',
    path: '/dashboard/home',
    sub: [],
  },
  {
    icon: PeopleIcon,
    title: 'Participantes',
    sub: [
      {
        title: 'Gestão de Participantes',
        path: '/dashboard/acompanhamento',
      },
      {
        title: 'Importar Participantes',
        path: '/dashboard/importacao',
      },
    ],
    path: '',
  },
  {
    icon: MechanicsIcon,
    title: 'Campanha',
    path: '',
    sub: [
      {
        title: 'Gestão de Etapas',
        path: '/dashboard/etapa',
      },
      {
        title: 'Metricas',
        path: '/dashboard/metricas',
      },
    ],
  },
  // {
  //   icon: BarGraphIcon,
  //   title: 'Metricas',
  //   path: '',
  //   sub: [],
  // },
  // {
  //   title: 'Importação',
  //   icon: ImportIcon,
  //   sub: [],
  //   path: '',
  // },
];

function Navbar() {
  const initialValues = {
    width: 14,
    display: 'none',
    rows: '1fr',
    showSub: false,
    data: {},
    bgIcon: 'transparent',
    strokeIcon: '#9a9a9a',
  };

  const [toggle, setToggle] = useState(initialValues);
  const [selectedItem, setSelectedItem] = useState({});

  function openMenu(item) {
    setSelectedItem(item);
    setToggle({
      ...toggle,
      width: 48,
      display: 'flex',
      rows: '1fr 70%',
      showSub: !!item.sub.length ?? false,
    });
  }

  function closeMenu() {
    setSelectedItem({});
    setToggle({
      ...toggle,
      width: 14,
      display: 'none',
      rows: '1fr',
      showSub: false,
    });
  }

  function openSubMenu(item) {
    setToggle({
      ...toggle,
      showSub: true,
      data: item,
    });
  }

  return (
    <Box
      as="nav"
      position="fixed"
      bottom="0"
      bg="linear-gradient(.31deg, #212121 0%, #3b3b3b 100%)"
      height={{
        base: 14,
        md: '100vh',
      }}
      width={{
        base: '100vw',
        md: toggle.width,
      }}
    >
      <Flex
        direction="column"
        height="100%"
        px="0"
        onMouseLeave={() => closeMenu()}
      >
        <Center height={14} borderBottom="1px solid #666">
          <Image
            height={8}
            width={8}
            objectFit="cover"
            src="/images/pat-logo-accent.svg"
            alt="logo"
          />
        </Center>
        {navItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <Grid
              key={index}
              templateColumns={toggle.rows}
              my={1}
              onMouseEnter={() => {
                console.log(item);
                openMenu(item);
              }}
              // onMouseOver={() => selectedItem.title === item.title && item.sub.length && openMenu(item)}
              _hover={{
                cursor: 'pointer',
              }}
            >
              <Center
                borderRadius="xl"
                margin={2}
                height={10}
                stroke={toggle.strokeIcon}
                bg={toggle.bgIcon}
                _hover={{
                  bg: '#FFFF63',
                  stroke: 'black',
                }}
                onClick={() => item.sub.length && openSubMenu(item)}
              >
                <Icon />
              </Center>
              <Center
                display={toggle.display}
                justifyContent="flex-start"
                height={10}
                margin=".5rem"
                color="#9a9a9a"
                fontSize="14px"
                cursor="pointer"
                // onClick={() => selectedItem.title === item.title && item.sub.length && openSubMenu(item)}
                onMouseEnter={() =>
                  selectedItem.title === item.title &&
                  item.sub.length &&
                  openSubMenu(item)
                }
              >
                {!item.sub.length ? (
                  <Link
                    as={NavLink}
                    to={item.path || ''}
                    onClick={() => closeMenu()}
                  >
                    {item.title}
                  </Link>
                ) : (
                  <>
                    {item.title}
                    <ChevronRightIcon position="absolute" right="5%" />
                    {toggle.showSub && (
                      <Subnavbar
                        props={toggle.data}
                        closeMenu={() => closeMenu()}
                      />
                    )}
                  </>
                )}
              </Center>
            </Grid>
          );
        })}
      </Flex>
    </Box>
  );
}

export default Navbar;
