import { useState, useEffect, useRef } from 'react';
import { Text, Box, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import ImportCard from '../components/VariablesImport/ImportCard';
import ImportList from '../components/VariablesImport/ImportList';
import {
  getImportList,
  postVariablesImport,
  getSheetExample,
  getReport,
} from '../services/api';

const VariablesImport = () => {
  const [isLoading, setLoading] = useState(false);
  const [isFetchingList, setFetchingList] = useState(false);
  const [file, setFile] = useState(null);
  const [importList, setImportList] = useState([]);
  const [dataValues, setDataValues] = useState(null);

  const inputFileRef = useRef(null);
  const toast = useToast();

  const { stepId } = useParams();

  useEffect(() => {
    const fetchImportList = async () => {
      try {
        setFetchingList(true);
        const data = await getImportList('variables');
        setImportList(data);
      } finally {
        setFetchingList(false);
      }
    };

    fetchImportList();
  }, []);

  const onChange = event => {
    if (event.target?.files[0]) {
      setFile(event.target?.files[0]);
    }
  };

  const onSubmit = async () => {
    try {
      if (!dataValues) {
        toast({
          position: 'top-right',
          title: 'Erro',
          description: 'Por favor, insira uma data válida.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!file) {
        toast({
          position: 'top-right',
          title: 'Erro',
          description: 'Por favor, selecione um arquivo.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      // formData.append('campaign_id', 'b715952f-ad8e-4f9d-8ac0-f373e3832dc5');
      formData.append('campaign_id', 'a7d2bd53-8533-4c6b-a0f6-50fd4ba1942a');
      formData.append('reference_date', dataValues);
      formData.append('step_id', stepId);

      toast({
        position: 'top-right',
        title: 'Importando...',
        description: 'Estamos importando sua planilha.',
        status: 'info',
        isClosable: true,
      });

      await postVariablesImport({ formData });

      toast({
        position: 'top-right',
        title: 'Importando...',
        description: 'Estamos importando sua planilha.',
        status: 'info',
        isClosable: true,
      });
      toast.closeAll();
      toast({
        position: 'top-right',
        title: 'Sucesso!.',
        description: 'Planilha importada com sucesso.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      setFile(null);
      inputFileRef.current.value = '';
    } catch (e) {
      toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExample = async () => {
    try {
      const data = await getSheetExample();
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const handleGetReport = async id => {
    try {
      const data = await getReport({ id });
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${id}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  return (
    <Box
      border="1px solid #F0F0F9"
      borderRadius="lg"
      margin={4}
      backgroundColor="white"
      width="100%"
      paddingX={8}
      paddingY={10}
    >
      <Text as="h2" fontSize="2xl" fontWeight="bold" color="grey.500" mb={3}>
        Atualize o banco de dados de métricas da campanha
      </Text>

      <ImportCard
        loading={isLoading}
        fileName={file?.name}
        onChange={onChange}
        onSubmit={onSubmit}
        handleSheetModel={handleDownloadExample}
        inputFileRef={inputFileRef}
        dataValues={new Date()}
        setDataValues={setDataValues}
        handleDownloadExample={handleDownloadExample}
      />
      <Box mt={10}>
        <Text as="h2" fontSize="md" fontWeight="bold" color="grey.500" mb={10}>
          Status das últimas importações
        </Text>
        <ImportList
          onClickItem={handleGetReport}
          importList={importList}
          isLoading={isFetchingList}
        />
      </Box>
    </Box>
  );
};

export default VariablesImport;
