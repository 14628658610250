import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import api from '../../services/api';
import { steps } from '../../services/patEndpoints';
import DateRangePicker from '../shared/DateRangePicker';

const ModalStep = ({ isOpen, onClose, onSuccess }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { handleSubmit, errors, register, setError } = useForm({
    defaultValues: { name: '' },
  });

  async function onSubmit(data) {
    const { name } = data;
    try {
      const { data: newStep } = await api.post(steps, {
        name,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        campaign: 'default',
      });

      return onSuccess(newStep);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || 'Erro de conexão, tente novamente';

      return setError('name', {
        type: 'api',
        message: errorMessage,
      });
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            borderBottomWidth="1px"
            borderBottomColor="grey.50"
            borderBottomStyle="solid"
          >
            Adicionar uma nova Etapa
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingX={8}>
            <FormControl id="name" mb="32px" isInvalid={errors.name}>
              <FormLabel fontWeight="bold" color="grey.200">
                Nome da Etapa
              </FormLabel>
              <Input
                ref={register}
                name="name"
                type="text"
                borderRadius="md"
                borderColor="#ddddee"
                _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </ModalBody>
          <ModalFooter as={HStack} p={8} spacing={8}>
            <Button
              width="100%"
              bg="transparent"
              mr={3}
              onClick={onClose}
              borderRadius="3xl"
              borderWidth="2px"
              borderColor="grey.500"
              borderStyle="solid"
              type="button"
            >
              Cancelar
            </Button>
            <Button
              width="100%"
              bg="brand-main"
              borderRadius="3xl"
              color="white"
              type="submit"
              _hover={{ bg: 'brand-brighter' }}
            >
              Adicionar Etapa
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ModalStep.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ModalStep;
