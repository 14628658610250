import { Flex, Text, Box, Input, Center, Spinner } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const InputFile = ({ onChange, fileName, loading, inputFileRef }) => (
  <form>
    <label htmlFor="file">
      <Flex
        justifyContent="space-between"
        pl={8}
        alignItems="center"
        bg="transparent"
        h="100%"
        width="100%"
        height="54px"
        border="1px solid #ddddee"
        borderRadius="md"
        cursor="pointer"
      >
        <Box>
          <Text>{fileName || 'Nenhum arquivo selecionado'}</Text>
        </Box>
        <Center
          width={28}
          borderLeft="1px solid #ddddee"
          height="100%"
          cursor="pointer"
          _hover={{ bg: 'grey.600' }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <Text fontWeight="bold" color="#4C6EF5">
              Adicionar
            </Text>
          )}
        </Center>
      </Flex>
      <Input
        display="none"
        ref={inputFileRef}
        type="file"
        id="file"
        onChange={onChange}
      />
    </label>
  </form>
);

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fileName: PropTypes.string,
  inputFileRef: PropTypes.func.isRequired,
};

InputFile.defaultProps = {
  fileName: null,
};

export default InputFile;
