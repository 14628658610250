import { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { stepEndpoint } from '../services/patEndpoints';
import api from '../services/api';
import Header from '../components/CampaignStepKpi/Header';
import KpiTable from '../components/CampaignStepKpi/KpiTable';

const KpiList = () => {
  const { stepId } = useParams();
  const [step, setStep] = useState({});

  useEffect(() => {
    const getStep = async () => {
      try {
        const { data } = await api.get(`${stepEndpoint}/${stepId}`);
        setStep(data);
      } catch (e) {
        setStep({});
      }
    };
    getStep();
  }, [stepId]);

  return (
    <>
      <Flex
        border="1px solid #F0F0F9"
        borderRadius="xl"
        margin={4}
        backgroundColor="#212121"
        width="100%"
        flexDir="column"
      >
        <Header
          stepId={stepId}
          name={step?.name}
          startDate={step?.start_date}
          endDate={step?.end_date}
        />
        <Flex
          borderRadius="lg"
          bg="white"
          width="100%"
          height="100%"
          flexDir="column"
        >
          <KpiTable stepId={stepId} />
        </Flex>
      </Flex>
    </>
  );
};

export default KpiList;
