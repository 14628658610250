import PropTypes from 'prop-types';
import { Text, HStack, Flex, Button, Stack, Skeleton } from '@chakra-ui/react';
import { format } from 'date-fns';

const ImportList = ({ importList, onClickItem, isLoading }) => {
  if (isLoading) {
    return (
      <Stack spacing={5}>
        <Skeleton height="60px" />
        <Skeleton height="60px" />
        <Skeleton height="60px" />
      </Stack>
    );
  }

  return importList.length ? (
    importList.map((importItem, index) => {
      const isSuccess = importItem?.importLog.status?.name === 'sucesso';
      const completeWithErros =
        importItem?.importLog.status?.name === 'concluida com erros';
      const colorImport = completeWithErros ? '#f5d046' : '#2AC644';

      return (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingX={6}
          paddingY={3}
          mt={index === 0 ? 0 : 5}
          borderRadius="sm"
          border="1px solid #F0F0F9"
          borderLeftColor={
            completeWithErros || isSuccess ? colorImport : '#F03E5E'
          }
          borderLeftWidth="5px"
          borderLeftStyle="solid"
          height="60px"
          key={importItem.id}
        >
          <HStack spacing={1}>
            <Text as="bold" fontWeight="bold">
              {isSuccess && 'Processado com sucesso!'}
              {completeWithErros && 'Concluída com erros!'}
              {!isSuccess && !completeWithErros && 'Erro no processamento!'}
            </Text>
            <Text>
              {`Planilha "${importItem.name}" - ${format(
                new Date(importItem.importLog.end_date),
                `dd/MM 'às' hh:mm`,
              )}`}
            </Text>
          </HStack>
          <Button
            bg={completeWithErros || isSuccess ? '#EBFFEE' : '#FEF5F7'}
            color={completeWithErros || isSuccess ? '#2AC644' : '#F03E5E'}
            fontWeight="bold"
            _hover={completeWithErros || isSuccess ? '#EBFFEE' : '#FEF5F7'}
            onClick={() => onClickItem(importItem.id)}
          >
            Baixar relatório
          </Button>
        </Flex>
      );
    })
  ) : (
    <HStack
      paddingX={6}
      paddingY={3}
      mt={5}
      borderRadius="sm"
      border="1px solid #F0F0F9"
      borderLeftColor="grey.50"
      borderLeftWidth="5px"
      borderLeftStyle="solid"
      spacing={1}
    >
      <Text color="grey.200" fontSize="xl">
        Nenhuma importação completada ainda
      </Text>
    </HStack>
  );
};

ImportList.propTypes = {
  importList: PropTypes.arrayOf().isRequired,
  onClickItem: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ImportList.defaultProps = {
  isLoading: false,
};

export default ImportList;
