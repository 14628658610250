import { Flex, Divider, Text, Heading, HStack } from '@chakra-ui/react';

const Footer = () => (
  <Flex
    as="footer"
    justifySelf="flex-end"
    direction="column"
    margin="auto auto 0 auto"
    marginTop="auto"
    pb="30px"
  >
    <Divider marginY="45px" marginX="-15px" />
    <Heading as="h3" fontWeight="700" color="grey.200" fontSize="xl" mb={2}>
      Central de Atendimento
    </Heading>
    <HStack spacing={5}>
      <Text fontWeight="700" color="grey.200" fontSize="sm">
        0800 643 8320
      </Text>
      <Text fontWeight="700" color="grey.200" fontSize="sm">
        contato@programalojaperfeita.com.br
      </Text>
    </HStack>
  </Flex>
);

export default Footer;
