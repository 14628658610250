import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ParticipantsTable from '../components/CampaignManagement/ParticipantsTable';
import LoadingState from '../components/CampaignManagement/LoadingState';
import {
  countParticipants,
  getParticipants,
  exportParticipants as apiExportParticipants,
} from '../services/api';

const CampaignManagement = () => {
  const history = useHistory();
  const params = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [, setSelectedParticipants] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  const [count, setCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const hasParticipants = participantList.length > 0;
  const [openMenuFilter, setOpenMenuFilter] = useState(false);
  const [countAllParticipants, setCountAllParticipants] = useState([]);
  const [filter, setFilter] = useState({});
  const [reloadFetch, setReloadFetch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [format, setFormat] = useState('xlsx');

  useEffect(() => {
    const fetchParticipantList = async () => {
      try {
        setLoading(true);
        const limit = 10;
        const offset = ((currentPage < 1 ? 1 : currentPage) - 1) * 10;
        const data = await getParticipants({ limit, offset, filter });
        setParticipantList(data.rows);
        setCount(data.count);
      } finally {
        setLoading(false);
      }
    };
    fetchParticipantList();
  }, [currentPage, reloadFetch]);

  useEffect(() => {
    const paramsFilter = new URLSearchParams(history.location.search).get(
      'filter',
    );

    setFilter(JSON.parse(paramsFilter) || {});
    setReloadFetch(prevState => !prevState);
  }, [history]);

  useEffect(() => {
    const fetchParticipantCount = async () => {
      try {
        setLoading(true);
        const data = await countParticipants({ filter });
        setCountAllParticipants(data.results);
      } finally {
        setLoading(false);
      }
    };
    fetchParticipantCount();
  }, [reloadFetch]);

  const findParticipantsWithFilter = async () => {
    setCurrentPage(1);
    setOpenMenuFilter(false);
    setReloadFetch(prevState => !prevState);
    history.replace({
      search: `?filter=${JSON.stringify(filter)}`,
    });
  };

  const exportParticipants = async () => {
    try {
      setLoading(true);
      const file = await apiExportParticipants({ format, filter });

      const data = new Blob([file], { type: `text/${format}` });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `export_participants.${format}`);
      tempLink.click();
    } finally {
      setLoading(false);
      setOpenModal(false);
    }
  };

  const addParticipant = participant =>
    setSelectedParticipants(prevState => [...prevState, participant]);

  const removeParticipant = participant =>
    setSelectedParticipants(prevState =>
      prevState.filter(item => item.id !== participant.id),
    );

  const handleSelectParticipants = ({ participant, isChecked }) =>
    isChecked ? addParticipant(participant) : removeParticipant(participant);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <ParticipantsTable
      participants={participantList}
      handleSelectParticipants={handleSelectParticipants}
      onChange={setCurrentPage}
      currentPage={currentPage}
      count={count}
      openMenuFilter={openMenuFilter}
      setOpenMenuFilter={setOpenMenuFilter}
      countAllParticipants={countAllParticipants}
      filter={filter}
      setFilter={setFilter}
      findParticipantsWithFilter={findParticipantsWithFilter}
      format={format}
      setFormat={setFormat}
      openModal={openModal}
      setOpenModal={setOpenModal}
      exportParticipants={exportParticipants}
    />
  );
};

export default CampaignManagement;
