/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgCurrency(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.727v0A2.127 2.127 0 009.873 5.6h-.746A2.127 2.127 0 007 7.727v0c0 1.958 5 1.418 5 3.545v0A2.127 2.127 0 019.873 13.4h-.746A2.127 2.127 0 017 11.273v0M9.667 4v.6M9.667 14.4v.6"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCurrency;
