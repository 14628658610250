/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgLock(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        strokeWidth={2}
        strokeLinejoin="round"
        d="M5 9h10v7H5z"
      />
      <path
        d="M7 6a3 3 0 013-3v0a3 3 0 013 3v3H7V6z"
        stroke={props.color}
        strokeWidth={2}
      />
      <circle
        cx={10}
        cy={12.5}
        r={0.75}
        stroke={props.color}
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default SvgLock;
