/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgQuestionmarkfilled(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10} cy={10} r={8.5} stroke={props.color} />
      <path
        d="M8 7.25C8 6.007 8.895 5 10 5s2 1.007 2 2.25-.895 2.25-2 2.25V11"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={10} cy={14} r={0.5} stroke={props.color} />
    </svg>
  );
}

export default SvgQuestionmarkfilled;
