/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgClip(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 5v0a3 3 0 00-3-3v0a3 3 0 00-3 3v9a3 3 0 003 3v0a3 3 0 003-3V8.273a1.5 1.5 0 00-1.5-1.5v0a1.5 1.5 0 00-1.5 1.5v3.954"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgClip;
