/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgProfile(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={10.177}
        cy={5.824}
        r={2.824}
        stroke={props.color}
        strokeWidth={2}
      />
      <path
        d="M5.022 14.367a.275.275 0 01-.007-.005c.099-1.674.68-2.834 1.482-3.593.892-.845 2.17-1.298 3.68-1.298 1.51 0 2.787.453 3.68 1.298.801.76 1.382 1.92 1.481 3.593a11.338 11.338 0 01-1.223.674c-1.04.493-2.433.964-3.938.964-1.506 0-2.9-.47-3.94-.964a11.34 11.34 0 01-1.215-.67z"
        stroke={props.color}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgProfile;
