import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const MiniBadge = ({ color, ...props }) => (
  <Box
    w={2}
    h={2}
    display="inline-flex"
    borderRadius="50%"
    borderColor={color}
    borderWidth="2px"
    borderStyle="solid"
    {...props}
  />
);

MiniBadge.propTypes = {
  color: PropTypes.string,
};

MiniBadge.defaultProps = {
  color: 'grey.600',
};

export default MiniBadge;
