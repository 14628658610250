import axios from 'axios';

import { patEndpoints } from './patEndpoints';

const api = axios.create({
  baseURL: process.env.REACT_APP_PAT_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('@PAT:token');
      localStorage.removeItem('@PAT:username');
      localStorage.setItem('@PAT:expired', 'true');
      window.location = '/';
    }

    return Promise.reject(error);
  },
);

export const getParticipants = async ({ limit, offset, filter }) => {
  console.log('getParticipants', limit, offset, filter);
  const { data } = await api.get(
    `${
      patEndpoints.participantsList
    }?campaign=default&limit=${limit}&offset=${offset}&filter=${JSON.stringify(
      filter,
    )}`,
  );
  return data;
};

export const countParticipants = async ({ filter }) => {
  const { data } = await api.get(
    `${
      patEndpoints.participantsList
    }/count?campaign=default&filter=${JSON.stringify(filter)}`,
  );

  return data;
};

export const exportParticipants = async ({ format, filter }) => {
  const { data } = await api({
    method: 'GET',
    url: `${
      patEndpoints.participantsList
    }/export?campaign=default&format=${format}&filter=${JSON.stringify(
      filter,
    )}`,
    responseType: 'blob',
  });

  return data;
};

export const getRoles = async () => {
  const { data } = await api.get(`${patEndpoints.participantsList}/roles`);

  return data;
};

export const getSteps = async () => {
  const { data } = await api.get('/admin/campaign/step/?campaign=default');
  return data;
};

export const getImportList = async (type = 'campaign') => {
  const { data } = await api.get(`/admin/campaign/import?type=${type}`);
  return data;
};

export const getImportLogStatus = async id => {
  const { data } = await api.get(`/admin/campaign/import/${id}/show`);
  return data;
};

export const getSheetExample = async () => {
  const { data } = await api.get({
    method: 'GET',
    url: `${process.env.REACT_APP_PAT_API_URL}/admin/campaign/config?campaign=default&config=import_model`,
    responseType: 'blob',
  });
  return data;
};

export const getEquations = async kpiId => {
  const { data } = await api.get(`/admin/kpi/equation/${kpiId}`);
  return data;
};

export const postEquations = async postData => {
  const { data } = await api.post('/admin/kpi/equation', postData);
  return data;
};

export const getReport = async ({ id }) => {
  const { data } = await api({
    method: 'GET',
    url: `/admin/campaign/import/${id}`,
    responseType: 'blob',
  });
  return data;
};

export const postCampaignImport = async ({ formData }) => {
  const { data } = await api.post('/admin/campaign/import', formData);
  return data;
};

export const postVariablesImport = async ({ formData }) => {
  await api.post('/admin/variables', formData);
};

export const postKpi = async ({ postData }) => {
  const { data } = await api.post('/admin/kpi', postData);
  return data;
};

export const putKpi = async ({ postData }) => {
  const { data } = await api.put('/admin/kpi', postData);
  return data;
};

export const getOneKpi = async ({ kpiId }) => {
  const { data } = await api.get(`/admin/kpi/${kpiId}`);
  return data;
};

export const getVariablesCampaign = async campaingStepId => {
  const { data } = await api.get(`/admin/variables?id=${campaingStepId}`);
  return data;
};

export default api;
