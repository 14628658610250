/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgSheet(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 11.667V7.333L10.417 3H4v13h11v-1"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.586 7H11V5.414L12.586 7z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="sheet_svg__a" fill="#fff">
        <rect x={9} y={10} width={8} height={3} rx={1} />
      </mask>
      <rect
        x={9}
        y={10}
        width={8}
        height={3}
        rx={1}
        stroke={props.color}
        strokeWidth={3}
        mask="url(#sheet_svg__a)"
      />
    </svg>
  );
}

export default SvgSheet;
