/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import * as React from 'react';

function SvgEdit(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 3l3 3-9.5 9.5h-3v-3L14 3z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path stroke={props.color} strokeWidth={2} d="M15.107 9.207l-4-4" />
    </svg>
  );
}

export default SvgEdit;
