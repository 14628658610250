import { useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';

import Sidebar from '../components/Login/Sidebar';
import Main from '../components/Login/Main';
import { useAuth } from '../hooks/useAuth';

const Login = () => {
  const toast = useToast();
  const authState = useAuth();

  useEffect(() => {
    if (authState?.token) {
      window.location.href = '/dashboard/home';
    }

    return null;
  }, [authState]);

  useEffect(() => {
    const isExpired = localStorage.getItem('@PAT:expired');

    if (isExpired) {
      localStorage.removeItem('@PAT:expired');
      toast({
        position: 'top-right',
        title: 'Sessão expirada.',
        description: 'Por favor, entre novamente para continuar.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  return (
    <Box
      height="100vh"
      mw="100%"
      display="flex"
      backgroundColor="grey.300"
      flexDirection="row"
    >
      <Sidebar />
      <Main />
    </Box>
  );
};

export default Login;
