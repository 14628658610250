import PropTypes from 'prop-types';
import { Flex, Text, Image, Box, Button, HStack } from '@chakra-ui/react';

import InputFile from '../shared/InputFile';

const { REACT_APP_PAT_API_URL } = process.env;

const ImportCard = ({
  onChange,
  isLoading,
  fileName,
  onSubmit,
  handleSheetModel,
  inputFileRef,
}) => (
  <Box border="2px solid #F0F0F9" maxW="630px" padding={8} borderRadius="md">
    <Flex alignItems="center" mb={8}>
      <Image
        width={24}
        height={24}
        objectFit="cover"
        src="/images/people-sheet.svg"
        alt=""
      />
      <Flex marginLeft={8} flexDir="column">
        <Text as="h3" fontSize="md" color="black" fontWeight="bold" mb={3}>
          Planilha de Participantes
        </Text>
      </Flex>
    </Flex>
    <InputFile
      onChange={onChange}
      loading={isLoading}
      inputFileRef={inputFileRef}
      fileName={fileName}
    />
    <HStack mt={8} spacing={8}>
      <a
        href={`${REACT_APP_PAT_API_URL}/admin/exports/participants`}
        target="_blank"
        rel="noreferrer"
        style={{
          width: '100%',
          backgroundColor: '#F0F0F9',
          color: 'black',
          borderRadius: '1.5rem',
          textAlign: 'center',
          padding: '0.5rem 1rem',
        }}
      >
        Baixar Modelo
      </a>

      <Button
        w="100%"
        bg="black"
        color="white"
        borderRadius="3xl"
        _hover={{ bg: 'brand-brighter' }}
        loading={isLoading}
        onClick={onSubmit}
      >
        Enviar planilha
      </Button>
    </HStack>
  </Box>
);

ImportCard.propTypes = {
  ...InputFile.propTypes,
  onSubmit: PropTypes.func.isRequired,
  handleSheetModel: PropTypes.func.isRequired,
};

ImportCard.defaultProps = {
  ...InputFile.defaultProps,
};

export default ImportCard;
