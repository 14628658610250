import * as React from 'react';

function SvgRoundedPlus(props) {
  return (
    <svg
      width={90}
      height={90}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={45}
        cy={45}
        r={44}
        fill="#fff"
        stroke="#3B3B3B"
        strokeWidth={2}
      />
      <path
        d="M45 23v45M67 45H22"
        stroke="#3B3B3B"
        strokeWidth={5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgRoundedPlus;
