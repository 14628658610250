import { Switch } from 'react-router-dom';

import CampaignImport from '../pages/CampaignImport';
import CampaignManagement from '../pages/CampaignManagement';
import CampaignStepKpi from '../pages/CampaignStepKpi';
import CampaignSteps from '../pages/CampaignSteps';
import DashboadLayout from '../components/shared/DashboardLayout';
import Home from '../pages/Home';
import KpiRules from '../pages/KpiRules';
import Metrics from '../pages/Metrics';
import VariablesImport from '../pages/VariablesImport';
import Route from './Route';

const Dashboard = () => {
  return (
    <>
      <DashboadLayout>
        <Switch>
          <Route exact path="/dashboard/home" isPrivate component={Home} />
          <Route
            exact
            path="/dashboard/importacao"
            component={CampaignImport}
          />
          <Route
            exact
            path="/dashboard/variaveis/importacao"
            component={VariablesImport}
          />
          <Route
            path="/dashboard/acompanhamento"
            exact
            component={CampaignManagement}
          />
          <Route path="/dashboard/etapa" exact component={CampaignSteps} />
          <Route path="/dashboard/metricas" exact component={Metrics} />
          <Route
            exact
            path="/dashboard/etapa/:stepId"
            component={CampaignStepKpi}
          />
          <Route
            exact
            path="/dashboard/etapa/:stepId/importacao"
            component={VariablesImport}
          />
          <Route
            exact
            path="/dashboard/etapa/:stepId/kpi/:kpiId"
            component={KpiRules}
          />
        </Switch>
      </DashboadLayout>
    </>
  );
};

export default Dashboard;
