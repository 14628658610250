import { useState, useEffect } from 'react';
import { Flex, Text, Center, Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DuplicateIcon, DeleteIcon, EditIcon, Prize } from '../shared/Icon';
import { kpi, kpiPrize } from '../../services/patEndpoints';
import api from '../../services/api';
import EmptyState from './EmptyState';
import KpiCreationModal from './KpiCreationModal';
import ModalDeleteKpi from './ModalDeleteKpi';
import {
  Table,
  TableBody,
  TableCell,
  TableCellText,
  TableHead,
  TableHeader,
  TableRow,
} from '../shared/DataTable';
import ModalPrizeKpi from "./ModalPrizeKpi";

const KpiTable = ({ stepId }) => {
  const history = useHistory();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isPrizeOpen, setIsPrizeOpen] = useState(false);
  const [kpiEdit, setKpiEdit] = useState({});
  const [kpiList, setKpiList] = useState([]);
  const [kpiListPrize, setKpiListPrize] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isEmptyList = !kpiList?.length;

  useEffect(() => {
    const getKpiList = async () => {
      try {
        const { data } = await api.get(`${kpi}/?step=${stepId}`);
        setKpiList(data);
      } catch (e) {
        setKpiList([]);
      }
    };
    getKpiList();
  }, [stepId]);

  const handleNavigate = kpiId =>
    history.push(`/dashboard/etapa/${stepId}/kpi/${kpiId}`);

  const onUpdate = kpiData => {
    setKpiEdit({ kpiData, isEdit: true });
    onOpen();
  };

  const onDuplicate = kpiData => {
    setKpiEdit({ kpiData, isEdit: false });
    onOpen();
  };

  const checkPrize = async (kpiId) => {
    setIsPrizeOpen(true);
    setLoading(true);

    try {
      const { data } = await api.get(`${kpiPrize}?kpi_id=${kpiId}`);
      setKpiListPrize(data.rankingFinal);
    } catch (e) {
      setKpiListPrize([]);
    }

    setLoading(false);
  };

  const handleDeleteKpi = async () => {
    try {
      await api.delete(`${kpi}/${kpiEdit.id}`);
      setKpiList(oldKpiList =>
        oldKpiList.filter(kpiItem => kpiItem.id !== kpiEdit.id),
      );
      setKpiEdit({});
      setIsAlertOpen(false);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const handleConfirmExclude = kpiId => {
    setKpiEdit({ id: kpiId });
    setIsAlertOpen(true);
  };

  const onSuccess = (newKpi, isEdit) => {
    setKpiList(oldKpiList =>
      isEdit
        ? oldKpiList.map(kpiItem =>
            kpiItem.id === newKpi.id ? newKpi : kpiItem,
          )
        : [...oldKpiList, newKpi],
    );
    setKpiEdit({});
  };

  if (isEmptyList) {
    return (
      <>
        <EmptyState handleAddKpi={onOpen} />
        <KpiCreationModal
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={onSuccess}
          stepId={stepId}
          {...kpiEdit}
        />
      </>
    );
  }

  return (
    <Flex flexDir="column">
      <Table overflowX="hidden" wrapperWidth="100%" w="100%">
        <TableHead>
          <TableRow>
            <TableHeader p={8}>
              <Text>Nome do Kpi</Text>
            </TableHeader>
            <TableHeader w="33%">Perfil Hierarquico</TableHeader>
            <TableHeader>Ações</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {kpiList.map(kpiItem => (
            <TableRow bg="white" key={kpiItem?.id}>
              <TableCell
                cursor="pointer"
                onClick={() => handleNavigate(kpiItem?.id)}
                px={8}
                py={4}
              >
                <TableCellText color="grey.500">
                  {kpiItem?.name || '-'}
                </TableCellText>
              </TableCell>
              <TableCell
                cursor="pointer"
                onClick={() => handleNavigate(kpiItem?.id)}
                px={8}
                py={4}
                w="60%"
              >
                <Text
                  bg="grey.700"
                  borderRadius="10px"
                  color="grey.300"
                  fontSize="sm"
                  p={1}
                  w="fit-content"
                >
                  {kpiItem?.participantRole?.name || '-'}
                </Text>
              </TableCell>
              <TableCell px={8} py={4}>
                <Flex>
                  <Box
                    as={DuplicateIcon}
                    cursor="pointer"
                    onClick={() => onDuplicate(kpiItem)}
                    mr={8}
                    stroke="black"
                  />
                  <Box
                    as={DeleteIcon}
                    cursor="pointer"
                    mr={8}
                    onClick={() => handleConfirmExclude(kpiItem.id)}
                    stroke="black"
                  />
                  <Box
                    as={EditIcon}
                    cursor="pointer"
                    onClick={() => onUpdate(kpiItem)}
                    mr={8}
                    stroke="black"
                  />
                  <Box
                    as={Prize}
                    cursor="pointer"
                    onClick={() => checkPrize(kpiItem.id)}
                    stroke="black"
                  />
                </Flex>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Center
        border="1px dashed #DDDDEE"
        cursor="pointer"
        h={16}
        maxW="100%"
        mt={8}
        mx={4}
        onClick={onOpen}
      >
        <Text fontWeight="bold" fontSize="md">
          + Adicionar KPI
        </Text>
      </Center>
      <KpiCreationModal
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={onSuccess}
        stepId={stepId}
        {...kpiEdit}
      />
      <ModalDeleteKpi
        isOpen={isAlertOpen}
        onCancel={() => setIsAlertOpen(false)}
        onDelete={handleDeleteKpi}
      />

      <ModalPrizeKpi
        isOpen={isPrizeOpen}
        onCancel={() => {
          setIsPrizeOpen(false);
          setKpiListPrize([]);
        }}
        loading={loading}
        data={kpiListPrize}
      />
    </Flex>
  );
};

KpiTable.propTypes = {
  stepId: PropTypes.string.isRequired,
};

export default KpiTable;
